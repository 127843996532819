.page.-customer-dashboard-page {
  .account-section-header {
    .link.all-orders {
      display: flex;
      align-items: center;
      color: $primaryPink;

      .link-text {
        @include hover-underline;
      }
      ._chevron {
        margin-left: 6px;

        path {
          stroke: $primaryPink;
        }
      }
    }
  }
}
