.product-services-area {
  > .dropdown-item {
    > .dropdown-header {
      padding: 12px 16px;
    }

    > .dropdown-body {
      padding-left: 16px;
      padding-right: 16px;
      background-color: $grey1;
    }
  }

  > .dropdown-item {
    > .dropdown-header {
      display: flex;
      align-items: center;

      ._shoppingtag {
        flex: 0 0 auto;
      }

      ._chevron {
        margin-left: auto;
        transform: rotate(90deg);
        transition: transform 0.5s;
        flex: 0 0 auto;
      }
    }

    .service-checkbox-container {
      display: flex;
      align-items: center;
      margin-top: 4px;
      margin-bottom: 4px;

      .checkbox-input,
      .radio-input {
        flex: 1 1;

        ._checkbox-wrapper,
        ._radio-wrapper ._children-wrapper {
          display: flex;

          > .service-name {
            flex: 1 1;
            margin-right: 12px;
            font-size: 14px;
          }

          > .service-price {
            font-weight: bold;
            color: $primaryPink;
            font-size: 14px;
          }

          > .service-deductible {
            color: $primaryPink;
            margin-right: 5px;
          }
        }
      }

      > .service-link {
        transform: scale(1.3);
        padding: 0 2px 6px 4px;

        &:hover,
        &:focus {
          cursor: pointer;
          color: $primaryPink;
          > svg,
          > svg path {
            fill: $primaryPink;
            stroke: white;
          }
        }
      }
    }

    .dropdown-body .radio-input {
      min-height: 48px;
      ._radio-wrapper {
        width: 100%;
        ._children-wrapper {
          width: 100%;
        }
      }
      padding: 0;
      height: auto;
      border: none;
      box-shadow: none;
    }

    // Fix checkboxes putting a margin-top on the _wrapper
    // which breaks height calculations
    .dropdown-body .checkbox-input:first-child ._checkbox-wrapper {
      margin-top: 0;
    }

    &.-open {
      > .dropdown-header {
        ._chevron {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
