.text-input {
  cursor: text;

  > ._input-wrapper {
    background: $grey1;
    height: 64px;
    border-radius: 4px;
    overflow: hidden;
    border: 2px solid transparent;
    padding: 11px 24px;
    position: relative;
    transition: all 300ms $transition;

    &.-dark {
      & > label {
        color: $grey4;
      }

      background: $primaryDarkBlue;
      &.-focus {
        border-color: $primaryPink;
        background: $grey5;
      }

      & > input {
        color: $grey1;
      }

      & > input::placeholder,
      textarea::placeholder {
        color: $grey5;
      }

      &.-disabled {
        background: $grey5;
      }
    }

    > .icon {
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 26px;
      transform: translateY(-50%);

      svg {
        height: 16px;
        width: 16px;
      }

      // Fix for input overlapping the icon
      ~ input {
        width: calc(100% - 88px);
      }
    }

    > label {
      position: absolute;
      transition: all 300ms $transition;
      top: 11px;
      left: 24px;

      @include typography-label-caption;
      text-transform: uppercase;
      height: 14px;
    }

    > input,
    textarea {
      position: absolute;
      bottom: 11px;
      left: 24px;
      right: 24px;
      top: 27px;
      width: calc(100% - 48px);
      background: none;
      border: none;
      outline: none;
      overflow: hidden;
      text-overflow: ellipsis;

      // Default input padding
      padding: 0;

      @include typography-desktop-text-x-small;

      &::placeholder {
        color: $grey3;
      }
    }

    &.-readonly {
      cursor: default;

      > input {
        cursor: default;
      }
    }

    &.-empty.-blur:not(.-medium) {
      > label {
        color: $grey4;
        top: 11px;
      }
      &:not(.-placeholder):not(.-medium) > label {
        color: $grey3;
        top: 25px;
      }
    }

    &.-focus {
      border-color: $primaryPink;
      background: #fff;

      > label {
        top: 11px;
      }
    }

    &.-error {
      border-color: $primaryPink;
    }

    &.-required {
      > label::after {
        content: '*';
      }
    }

    &.-disabled {
      &,
      > label,
      > input {
        cursor: not-allowed;
      }

      > label {
        color: $grey3;
      }

      > input {
        // Use one shade darker color: fix for safari
        color: $grey4;
      }
    }

    &.-compact {
      height: 34px;
      border-radius: 8px;
      padding: 18px 16px;

      > label {
        display: none;
      }

      > input {
        width: calc(100% - 36px);
        bottom: 0px;
        left: 16px;
        right: 16px;
        top: 0px;

        &::placeholder {
          @include typography-label-caption($grey3);
          text-transform: uppercase;
        }
      }

      > .icon {
        top: 55%;
        right: 16px;

        ~ input {
          width: calc(100% - 64px);
        }
      }
    }

    &.-medium {
      height: 48px;
      border-radius: 8px;
      padding: 18px 16px;

      &.-focus,
      &:not(.-empty) {
        > label {
          display: none;
        }
      }

      > label {
        position: absolute;
        top: 16px;
        left: 24px;

        @include typography-label-caption;
        text-transform: uppercase;
        height: 14px;
      }

      > input {
        width: calc(100% - 36px);
        bottom: 0px;
        left: 16px;
        right: 16px;
        top: 0px;

        &::placeholder {
          @include typography-label-caption($grey3);
          text-transform: uppercase;
        }
      }

      > .icon {
        top: 50%;
        right: 16px;

        ~ input {
          width: calc(100% - 64px);
        }
      }
    }

    &.-textarea {
      height: 150px;

      > .icon {
        top: 55%;
        right: 24px;

        ~ textarea {
          width: calc(100% - 80px);
        }
      }

      > textarea {
        height: calc(100% - 48px);
        overflow-y: auto;
        resize: none;
      }
    }
  }

  > ._error {
    @include typography-error-message-desktop;
  }
}

._tel {
  display: flex;
  justify-content: space-between;

  & > .text-input {
    display: inline-block;

    &:first-child {
      width: 30%;
      min-width: 95px;
      margin-right: 8px;
    }
    &:last-child {
      width: 69%;
      margin-left: 2px;
    }
  }
}
