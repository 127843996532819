.toasts-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  padding: inherit;
  z-index: 9999999;

  @include bp($mobileBreakpoint) {
    bottom: auto;
    top: 24px;
    right: 48px;
    left: 48px;
  }

  $maxHeight: 220px; // 52 padding + 7 * 24 (5 lines)
  $transitionDuration: 400ms;

  > ._toast {
    cursor: pointer;
    max-height: $maxHeight;
    width: 300px;
    background: #fff;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid transparent;
    color: $primaryBlack;
    padding: 26px;
    @include inter(500);
    font-size: 16px;
    line-height: 24px;
    @include box-shadow-full;
    border-bottom: 2px solid $primaryPink;
    overflow: hidden;

    @include bp($mobileBreakpoint) {
      width: 100%;
    }

    &.-success {
      border-bottom-color: #75ba01;
    }

    &.-error {
      border-bottom-color: $primaryPink;
    }

    &.-info {
      border-bottom-color: $grey3;
    }

    &.enter {
      opacity: 0;
      max-height: 0;
      padding: 0 26px;
      margin-bottom: 0px;
      transform: scale(0);
    }

    &.enter-active {
      opacity: 1;
      max-height: $maxHeight;
      padding: 26px;
      margin-bottom: 16px;
      transform: scale(1);
      transition: all $transitionDuration $transition;
    }

    &.exit {
      opacity: 1;
      max-height: $maxHeight;
      padding: 26px;
      margin-bottom: 16px;
    }

    &.exit-active {
      opacity: 0;
      max-height: 0;
      padding: 0 26px;
      margin-bottom: 0px;
      transition: all $transitionDuration $transition;
    }
  }
}
