.product-carousel-wrapper {
  > .product-carousel--fake-bg {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    background: $grey1;
    z-index: 0;
  }

  .product-carousel {
    display: flex;
    flex-direction: row;

    // Necessary to display back/fwd buttons correctly
    width: calc(100% + 100px);
    margin-left: -50px;
    padding: 0 50px;

    @include bp(1400px) {
      width: 100%;
      margin: 0;
    }

    @include bp($mobileBreakpoint) {
      flex-direction: column;
      gap: 0rem;
      width: 100%;
      padding: 0;
    }

    @media (hover: none) {
      margin-bottom: 0;
    }

    .carousel__content__static-wrapper {
      flex: 1;
      width: 100%;
    }

    > .carousel__dots {
      margin-top: -8px;
    }

    ._product-group {
      display: flex;
      justify-content: space-between;
      width: calc(100% + 48px);
      padding: 24px 32px 56px 32px;
      list-style: none;
      gap: 8px;
      margin: 0 0 -12px -24px;

      @include bp($mobileBreakpoint) {
        padding: 28px 24px 44px 24px;
      }

      @media (hover: none) {
        padding-bottom: 16px;
      }

      > ._carousel-product {
        width: 100%;
        flex: 1;
        position: relative;
        border-radius: 8px;

        &:not(.-empty) {
          background: $grey1;

          &.-white-bg {
            background: #fff;
          }

          @media (hover) {
            &::after {
              content: '';
              display: block;
              background-color: $grey1;
              box-shadow:
                0px 16px 24px 0px rgba(0, 0, 0, 0.06),
                0px 2px 6px 0px rgba(0, 0, 0, 0.04),
                0px 0px 1px 0px rgba(0, 0, 0, 0.04);
              top: 0px;
              bottom: 0px;
              right: 0px;
              left: 0px;
              position: absolute;
              z-index: -1;
              opacity: 0;
              transition: all 0.3s $transition;
              border-radius: 8px;
            }

            &.-white-bg::after {
              background-color: #fff;
            }

            &:hover {
              z-index: 15;

              &::after {
                opacity: 1;
                top: -8px;
                bottom: -8px;
                right: 0px;
                left: 0px;
              }

              @include bp($mobileBreakpoint) {
                &::after {
                  display: none;
                }
              }

              ._name {
                color: $primaryPink !important;
              }

              ._image-wrapper {
                > img {
                  transform: scale(1.125);
                }
              }

              > .button.-primary {
                visibility: visible;
                opacity: 0;
                animation-name: fade-btn-in;
                animation-fill-mode: forwards;
                animation-play-state: running;
                animation-duration: 0.2s;
              }

              @keyframes fade-btn-in {
                0% {
                  opacity: 0;
                  transform: scale(0.6);
                }

                100% {
                  opacity: 1;
                  transform: scale(1);
                }
              }
            }
          }
        }

        > ._link-wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 24px;

          &:visited ._name {
            color: $grey5;
          }

          > ._name {
            color: $primaryBlack;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            word-break: break-word;
            margin-top: auto;
            transition: all 0.3s $transition;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          > ._image-wrapper {
            height: 156px;
            max-width: 156px;
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;

            @include bp($mobileBreakpoint) {
              height: 104px;
            }

            > img {
              width: 100%;
              max-height: 100%;
              object-fit: contain;
              object-position: center;
              mix-blend-mode: multiply;
              transition: all 0.3s $transition;
            }
          }

          > .product-page-price {
            margin-bottom: 16px;
          }

          .button.-primary {
            width: 100%;
            margin-top: 10px;
            visibility: hidden;

            @media (hover: none) {
              display: none;
            }
          }
        }
      }
    }
  }

  &.-grey-mode {
    ._product-group {
      > ._carousel-product:not(.-empty) {
        background-color: white;

        > ._link-wrapper {
          &::after {
            background-color: white;
          }
        }
      }
    }
  }
}
