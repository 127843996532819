.loader-wrapper {
  &.-absolute {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  background: rgba(#fff, 0.8);

  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
    transition: all 0.4s $transition;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
    transition: all 0.4s $transition;
  }
}
