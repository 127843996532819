.account-table-wrapper {
  overflow-x: auto;

  .account-table {
    display: table;
    border-collapse: separate;
    border-spacing: 0 8px;
    width: 100%;

    > :not(.no-results-container) {
      @include minbp($mobileBreakpoint) {
        min-width: 800px;
      }
    }

    .account-table-cell,
    .account-table-header {
      padding: 0 24px;
      vertical-align: middle;
      display: table-cell;
    }

    .account-table-row {
      display: table-row;
    }

    .account-table-header {
      padding-bottom: 12px;
      text-align: left;
      @include typography-label-caption($grey4);
    }

    .account-table-cell {
      height: 80px;
      background-color: #fff;
      border: solid 1px transparent;
      border-style: solid none;

      .caption-label {
        margin-top: 2px;
        @include typography-label-caption($grey4);
      }

      // Default typography
      @include typography-desktop-text-x-small;

      &:last-child {
        border-right-style: solid;
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:first-child {
        border-left-style: solid;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
    }

    .account-row-buttons {
      display: flex;
      justify-content: flex-end;

      > * {
        margin-left: 8px;
      }
    }
  }
}
