div.box-dates {
  display: flex;

  .box-date {
    &:not(:last-of-type) {
      padding-right: 24px;
      margin-right: 24px;
      border-right: 1px solid $grey2;
    }

    > ._name {
      display: block;
      @include typography-label-caption();
      text-align: right;
      margin-bottom: 8px;
    }

    > ._value {
      display: block;
      @include typography-desktop-text-x-small($grey5);
    }
  }
}
