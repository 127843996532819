.category-page-seo-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  max-width: 1272px; // 1224 + padding 48px
  margin: 0 auto;
  background-color: $grey1;

  @include bp($mobileBreakpoint) {
    flex-direction: column;
  }
  ._content {
    h2 {
      @include typography-desktop-display-small-medium;
      margin-top: 16px;
      color: $primaryDarkBlue;
    }

    p {
      @include typography-desktop-text-x-small($grey5);

      b {
        @include typography-desktop-text-small;
      }
    }
  }

  ._seo-image-wrapper {
    height: 100%;
    max-width: 100%;
    flex: 1;
    position: relative;
    align-self: center;

    img {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      border-radius: 8px 0 0 8px;
      @include bp($mobileBreakpoint) {
        border-radius: 0;
      }
    }
  }

  ._seo-body {
    padding: 40px 36px;
    // min-width: 400px;
    flex: 1;
    a {
      color: $primaryPink;
      @include hover-underline;
    }
  }

  ._intro {
    @include typography-label-caption;
  }
}
