.login-form {
  form {
    > * {
      margin-bottom: 8px;
    }

    .submit-btn {
      margin-top: 15px;
      width: 100%;
      text-align: center;
    }
  }

  .forgot-password {
    margin-top: 20px;

    span {
      cursor: pointer;
      @include typography-desktop-text-x-small($primaryPink);
      @include hover-underline;
    }
  }
}
