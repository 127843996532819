div.loader {
  position: relative;
  height: 52px;
  width: 52px;
  margin: 26px;

  &.enter {
    opacity: 0;
    transform: scale(0);
  }

  &.enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 0.4s $transition;
  }

  &.exit {
    opacity: 1;
    transform: scale(1);
  }

  &.exit-active {
    opacity: 0;
    transform: scale(0);
    transition: all 0.4s $transition;
  }

  ._core {
    background: $primaryPink;
    height: 26px;
    width: 26px;
    border-radius: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;

    background: url('../../../images/logos/arvutitark-round.svg') no-repeat center;
    background-size: cover;
  }

  ._lines-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;

    ._lines {
      animation: loader 0.9s infinite;
      height: 100%;
      width: 100%;

      position: absolute;
      top: 0;
      bottom: 0;
      right: -50%;
      left: -50%;
      margin: auto;

      > span {
        height: 14px;
        position: absolute;
        width: 14px;
        z-index: 1;
      }

      > span:before,
      > span:after {
        background: rgba($primaryPink, 0.2);
        content: '';
        position: absolute;
      }

      > span:before {
        left: 0;
        height: 1px;
        width: 100%;
      }

      > span:after {
        height: 100%;
        top: 0;
        width: 1px;
      }

      > span:nth-child(5),
      > span:nth-child(2),
      > span:nth-child(5):before,
      > span:nth-child(2):before {
        top: 0;
      }

      > span:nth-child(3),
      > span:nth-child(4),
      > span:nth-child(3):before,
      > span:nth-child(4):before {
        bottom: 0;
      }

      > span:nth-child(5),
      > span:nth-child(3),
      > span:nth-child(5):after,
      > span:nth-child(3):after {
        left: 0;
      }

      > span:nth-child(2),
      > span:nth-child(4),
      > span:nth-child(2):after,
      > span:nth-child(4):after {
        right: 0;
      }
    }
  }

  &.-small {
    height: 28px;
    width: 28px;

    > ._core {
      height: 13px;
      width: 13px;
    }

    > ._lines {
      > span {
        height: 7px;
        width: 7px;
      }
    }
  }

  @keyframes loader {
    0% {
      height: 100%;
      width: 100%;
      transform: rotate(-90deg);
    }
    25% {
      height: 180%;
      width: 180%;
      transform: rotate(-90deg);
    }
    50% {
      height: 180%;
      width: 180%;
      transform: rotate(-180deg);
    }
    75% {
      height: 100%;
      width: 100%;
      transform: rotate(-180deg);
    }
    100% {
      height: 100%;
      width: 100%;
      transform: rotate(-180deg);
    }
  }
}

div.page-loader {
  $height: 4px;

  overflow: hidden;
  transition: all 1.5s $transition 0.2s;
  z-index: 15;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  right: 16px;
  z-index: 10;
  border-radius: 32px;

  > ._bar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: $height;
    background: transparent;

    > ._progress {
      position: absolute;
      top: 0;
      left: 0;
      height: $height;
      background: $primaryPink;
      background: linear-gradient(90deg, rgba($primaryPink, 0.4) 0%, $primaryPink 100%);
      animation: fill-progress;
      animation-timing-function: cubic-bezier(0.5, 0.5, 0, 1);
      animation-duration: 7000ms;
      animation-fill-mode: forwards;

      &.-finished {
        animation-duration: 1000ms;
      }

      @keyframes fill-progress {
        from {
          width: 0%;
        }
        to {
          width: 100%;
        }
      }
    }
  }

  > ._backdrop {
    position: absolute;
    top: $height;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
  }
}
