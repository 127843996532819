.account-section {
  margin-top: 36px;

  .account-section-header {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    @include bp(600px) {
      padding: 0;
    }
  }

  .account-section-title {
    display: flex;
    align-items: center;

    span._title {
      @include typography-desktop-text-large();
      margin-right: 16px;
    }

    margin: 4px 32px 4px 0;

    @include bp($mobileBreakpoint) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 16px;
    }
  }
}
