.catalogue-product-wrapper.-compact .product-main-attributes {
  display: flex;
  flex-wrap: wrap;

  li {
    position: relative;

    ._value {
      width: 100%;
    }

    &:not(:last-of-type) {
      margin-right: 20px;

      &::before {
        content: '·';
        position: absolute;
        right: -10px;
      }
    }
  }

  @include bp($mobileBreakpoint) {
    display: none;
  }
}
