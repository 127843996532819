.catalogue-product-wrapper.-compact {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .catalogue-product-link {
    overflow: hidden;
    width: 100%;
  }

  .catalogue-product {
    display: flex;
    width: 100%;
  }

  .add-to-cart-btn {
    flex-shrink: 0;
    margin-left: 12px;
    &.-secondary {
      display: none;
    }
  }

  ._body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 16px;
    overflow: hidden;
    flex: 1;

    @include bp($mobileBreakpoint) {
      padding-right: 0;
    }

    ._name-wrapper {
      display: flex;

      @include bp($mobileBreakpoint) {
        ._name {
          @include inter(500);
          font-size: 14px;
        }
      }
    }
  }

  ._image-wrapper {
    height: 80px;
    width: 80px;
    min-width: 80px;
    position: relative;
    margin-right: 12px;

    img {
      object-fit: contain;
      object-position: center;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
