section.product-page-price-box {
  > ._bottom-section {
    background: $grey1;
    flex: 1;
    padding: 24px;
  }

  > ._not-available {
    background: $grey1;
    padding: 32px;

    > ._title {
      color: $primaryDarkBlue;
      font-size: 18px;
      @include inter(600);
    }

    > ._description {
      margin-top: 24px;
      font-size: 16px;
      line-height: 24px;
      @include typography-desktop-text-x-small;
      color: $grey5;
    }
  }
}
