div.modal {
  $modalTransitionDuration: 400ms;

  z-index: 1100;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  > ._modal-backdrop {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &.-can-close {
      cursor: pointer;
    }

    background: rgba($primaryBlack, 0.3);
    backdrop-filter: blur(2px);
  }

  > ._modal-content {
    background: #fff;
    box-shadow: 0px 32px 64px rgba(17, 17, 17, 0.08);
    min-width: 320px;
    max-width: 80vw;
    min-height: 300px;
    max-height: 83vh;
    border-radius: 8px;
    z-index: 2;
    cursor: initial;
    position: relative;
    -webkit-overflow-scrolling: touch;

    @include bp($mobileBreakpoint) {
      width: 100%;
      max-width: 100%;
      border-radius: 8px 8px 0 0;
    }

    > ._close-btn {
      position: absolute;
      top: 16px;
      right: 20px;
      cursor: pointer;
      height: 24px;
      width: 24px;
      padding: 5px;
      z-index: 1; // Fix content width 100% issue

      > svg {
        width: 100%;
        height: 100%;
      }

      > svg > path {
        stroke: $primaryBlack;
      }

      &:hover {
        > svg > path {
          stroke: $primaryPink;
        }
      }
    }
  }

  &.enter {
    > ._modal-backdrop {
      opacity: 0;
    }

    > ._modal-content {
      opacity: 0;
      transform: translateY(120%);
    }
  }

  &.enter-active {
    > ._modal-backdrop {
      opacity: 1;
      transition: all $modalTransitionDuration $transition;
    }

    > ._modal-content {
      opacity: 1;
      transform: translateY(0%);
      transition: all $modalTransitionDuration $transition;
    }
  }

  &.exit {
    > ._modal-backdrop {
      opacity: 1;
    }

    > ._modal-content {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  &.exit-active {
    > ._modal-backdrop {
      opacity: 0;
      transition: all $modalTransitionDuration $transition;
    }

    > ._modal-content {
      opacity: 0;
      transform: translateY(120%);
      transition: all $modalTransitionDuration $transition;
    }
  }
}
