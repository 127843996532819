.campaign-page-modular-image-text-section {
  display: flex;

  @include bp($mobileBreakpoint) {
    flex-direction: column;
  }

  &.-image {
    &.-image-left > ._image {
      margin-right: 126px;

      @include bp($mobileBreakpoint) {
        margin-right: 0;
        margin-bottom: 24px;
      }
    }

    &.-image-right {
      flex-direction: row-reverse;

      > ._image {
        margin-left: 126px;
      }

      @include bp($mobileBreakpoint) {
        flex-direction: column;

        > ._image {
          margin-left: 0;
          margin-bottom: 24px;
        }
      }
    }
  }

  &.-no-image {
    align-items: center;
    justify-content: center;

    > ._text {
      display: block;
      flex: none;

      > ._content {
        max-width: none;
      }
    }
  }

  > ._image {
    max-height: 280px;
    max-width: 330px;
    overflow: hidden;
    flex: 1;

    > img {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  // section text color always overrides page text color
  &:not(.-section-text-dark) {
    > ._text {
      ._campaign-page-wrapper.-text-light & {
        > ._title {
          color: #fff;
        }

        > ._content {
          &,
          > *,
          > * > * {
            color: #fff;
          }
        }
      }
    }
  }

  &:not(.-section-text-light) {
    > ._text {
      ._campaign-page-wrapper.-text-dark &:not(.dark) {
        > ._title {
          color: $primaryBlack;
        }

        > ._content {
          &,
          > *,
          > * > * {
            color: $primaryBlack;
          }
        }
      }
    }
  }

  > ._text {
    flex: 1;
    width: 100%;

    > ._title {
      @include typography-desktop-text-large;
    }

    > ._content {
      margin-top: 16px;
      max-width: 493px;
    }

    > ._button-link {
      display: inline-block;
      margin-top: 24px;
    }

    > ._content {
      p {
        a {
          @include hover-underline();
          color: $primaryPink !important;
        }
      }
    }
  }
}
