.checkout-page-customer-info-form-section {
  display: flex;
  flex-direction: column;

  margin-top: 22px;

  .searchable-select-wrapper {
    width: 100%;
  }

  @include bp($mobileBreakpoint) {
    margin-top: 16px;
  }

  ._forms-anim-wrapper {
    // In-out animation
    transition: all 0.2s $transition;

    &.enter {
      opacity: 0;
      transform: translateY(100px);
    }

    &.enter-active {
      opacity: 1;
      transform: translateY(0px);
    }

    &.exit {
      opacity: 1;
      transform: translateY(0px);
    }

    &.exit-active {
      opacity: 0;
      transform: translateY(100px);
    }
  }
}

// > form {
//   ._form-section-title {
//     @include typography-desktop-text-medium;
//     transition: all 0.2s $transition;

//     &.-visible {
//       height: 26px;
//       margin-bottom: 16px;
//       opacity: 1;
//       transform: scaleY(1);
//     }

//     &.-hidden {
//       opacity: 0;
//       height: 0;
//       margin-bottom: 0;
//       transform: scaleY(0);
//     }
//   }

//   > ._forms-wrapper {
//     display: flex;

//     > ._left {
//       > ._text-inputs-wrapper {
//         max-width: 325px;
//         margin-bottom: 19px;

//         > .text-input {
//           margin-top: 16px;
//         }
//       }
//     }

//     > ._right {
//       .text-input {
//         margin-top: 16px;
//       }

//     }
//   }
// }
