.catalogue-header {
  > ._top-row {
    display: flex;
    align-items: center;

    > .result-count-wrapper {
      max-width: 300px;

      @include bp(1000px) {
        max-width: 270px;
      }

      margin-top: auto;
      height: 40px;
      display: flex;
      align-items: center;
    }

    > .paginator-wrapper {
      margin-top: auto;

      > .paginator {
        margin-bottom: 0;
      }

      @include bp(1024px) {
        display: none;
      }
    }

    > ._right-buttons {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;

      .toggle-input {
        @include bp(900px) {
          display: none;
        }
      }

      ._selects {
        margin-top: -20px;
        display: flex;

        > *:first-child {
          margin-right: 16px;
        }
      }

      ._desktop {
        display: block;

        @include bp($mobileBreakpointCatalogue) {
          display: none;
        }
      }

      ._mobile {
        display: none;
        @include bp($mobileBreakpointCatalogue) {
          display: block;
        }

        .filter-results-btn {
          @include typography-mobile-text-x-small($grey5);
        }
      }
    }
  }

  > ._bottom-row {
    display: flex;
    margin-top: 16px;

    .toggle-input {
      margin-left: auto;

      @include minbp($mobileBreakpointCatalogue) {
        display: none;
      }
    }
  }
}
