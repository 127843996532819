.catalogue-filters {
  &._mobile {
    // fix for inputs disappearing behind keyboard in Safari
    margin-bottom: 300px;
  }

  > .dropdown-item {
    margin: 40px 0;

    > .dropdown-header {
      display: flex;
      align-items: center;

      ._chevron {
        margin-left: auto;
        transform: rotate(90deg);
        transition: transform 0.5s;
      }

      ._name {
        @include typography-desktop-text-small;

        @include bp($mobileBreakpoint) {
          font-size: 16px;
        }
      }
    }

    ._checkbox-wrapper {
      display: flex;
      margin: 0; // visually hiding elements breaks margin collapse logic
      padding-top: 6px;
      padding-bottom: 6px;

      .check {
        top: 8px;
      }
    }

    @include bp($mobileBreakpoint) {
      .dropdown-header {
        margin-top: 24px;
      }
    }

    ._input-wrapper {
      background-color: white;
      border: 1px solid rgba($primaryDarkBlue, 0.05);

      .text-input {
        margin-bottom: 12px;
        margin-top: 12px;
        margin-right: 24px;

        .icon {
          cursor: pointer;
        }
      }
    }

    .checkbox-input {
      max-height: 128px;
      transition: all 400ms $transition;

      ._text {
        // limit weird long attribute values
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-right: 2px; // text is always cut off by a tiny bit on Safari?
      }

      &.hidden {
        overflow: hidden;
        max-height: 0;
      }
    }

    .not-found {
      display: block;
      margin-right: 24px;
      @include typography-desktop-text-x-small($grey3);
    }

    .price-filter {
      padding-bottom: 16px;
    }

    &.-open {
      > .dropdown-header {
        ._chevron {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
