div.dropdown-item {
  > .dropdown-header {
    cursor: pointer;
  }

  > .dropdown-body {
    overflow: hidden;
    transition: all 0.3s $transition;

    > * {
      opacity: 0;
      transition: 0.5s;
    }

    ._show-more {
      @include hover-underline;
      width: fit-content;
      color: $primaryPink;
      font-size: 16px;

      &:hover {
        cursor: pointer;
        svg {
          path {
            stroke: $primaryPink;
          }
        }
      }

      svg {
        &.up {
          transform: rotate(90deg);
        }
        &.down {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .dropdown-preview {
    @include typography-desktop-text-x-small($grey5);
    visibility: visible;
    height: auto;
    opacity: 1;
    transition:
      opacity 0.5s,
      height 1s;
  }

  &.-open {
    > .dropdown-body {
      max-height: 3000px;
      padding-top: 12px;

      > * {
        opacity: 1;
      }
    }

    > .dropdown-preview {
      opacity: 0;
      height: 0;
      visibility: hidden;
    }
  }

  &:not(.-open) {
    > .dropdown-body {
      padding: 0;
    }
  }
}
