div.modal > ._modal-content.partpayment-modal {
  padding: 40px;
  min-width: 600px;

  @include bp($mobileBreakpoint) {
    min-width: initial;
  }

  > ._close-btn {
    top: 40px;
    right: 40px;
  }

  > ._header {
    @include typography-desktop-text-large;
  }

  > ._sliders {
    margin-top: 18px;

    .slider-input {
      margin-bottom: 20px;
    }
  }

  > ._results {
    display: flex;
    align-items: center;
    margin-top: 20px;

    > ._result-title {
      @include inter(700);
      font-size: 16px;
      line-height: 24px;
      color: $primaryDarkBlue;
    }

    > ._result-value {
      margin-left: auto;
      color: $primaryPink;
      font-size: 24px;
      @include inter(500);
      line-height: 32px;
    }
  }

  > ._footer {
    margin-top: 14px;
    display: flex;
    align-items: center;

    > ._notice {
      @include inter(400);
      color: $grey4;
      font-size: 12px;
      line-height: 24px;
      max-width: 380px;
      margin-right: 24px;
    }

    > ._logo-wrapper {
      width: 80px;
      margin-left: auto;
    }
  }
}
