.fslightbox-container {
  // fixes annoying flicker https://github.com/banthagroup/fslightbox-react/issues/184
  .fslightbox-fade-out-strong {
    animation: none;
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s 0.25s,
      opacity 0.25s ease;
  }

  .fslightboxswc {
    @include bp(768px) {
      background: white;
    }
  }

  .fslightboxswc > .fslightbox-full-dimension > div > div {
    width: 1920px;
    height: 1000px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    overflow: hidden;
    margin-top: -60px;
    padding: 48px;

    > img {
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;

      @include bp(768px) {
        object-fit: contain;
      }
    }

    @include bp(2112px) {
      width: 1700px;
      height: 800px;
    }

    @include bp(1921px) {
      width: calc(100vw - 192px);
      height: 800px;
    }

    @include bp(1500px) {
      width: 1072px;
      height: 672px;
      padding: 36px;
    }

    @include bp(1100px) {
      width: 700px;
      height: 467px;
    }

    @include bp(768px) {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
      padding: 24px;
    }
  }

  .fslightbox-thumbs {
    height: 106px;

    > .fslightbox-thumbs-inner .fslightbox-thumb {
      height: 92px !important;
      width: 92px !important;
      object-fit: contain;
      object-position: center;
      background: #fff;
      border-radius: 8px;
      overflow: hidden;

      &.fslightboxta {
        border-color: $primaryPink !important;
      }
    }
  }
}
