.-shops-page.info-blocks {
  margin: 54px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include minbp(1000) {
    align-items: flex-start;
  }

  @include bp(1000) {
    margin: 24px 0;
    flex-direction: column;
  }

  .contact-info__row {
    flex: 1;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 24px 34px 24px;
    background-color: $grey1;

    @include minbp($mobileBreakpoint) {
      min-height: 220px;
    }

    @include minbp(1000) {
      flex-basis: calc(50% - 12px);

      &:nth-of-type(even) {
        margin-left: 24px;
      }
    }

    .contact-info__row-title {
      @include typography-desktop-text-large;
    }

    .contact-info__row-item {
      &._important,
      ._link {
        color: $primaryPink;
      }
      &._important {
        font-weight: 600;
      }

      ._link {
        @include hover-underline;
      }
    }
  }
}
