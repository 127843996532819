.register-form {
  transition: all 500ms $transition;

  form {
    .client-type {
      display: flex;
      margin-bottom: 16px;

      > :first-child {
        margin-right: 44px;
      }
    }

    > * {
      margin: 8px 0;
    }

    // Join newsletter checkbox
    .checkbox-input:last-of-type {
      margin-top: 22px;
    }

    > ._error {
      @include typography-error-message-desktop;
    }

    .submit-btn {
      margin-top: 16px;
      width: 100%;
      text-align: center;
    }
  }
}
