.page.-home-page {
  padding-bottom: 0;

  // Highlights section WYSIWYG
  .home-page-highlights-box-content {
    width: 100%;

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;

      @include bp(768px) {
        gap: 4px;
      }

      li {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        padding: 4px 8px 4px 8px;
        border-radius: 8px;
        transition: all 0.3s $transition;
        opacity: 90%;

        &:hover {
          opacity: 100%;
          background: rgba(255, 255, 255, 0.1);

          &:after {
            opacity: 100;
          }
        }

        &:after {
          content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.5 7L14.3586 11.8586C14.4367 11.9367 14.4367 12.0633 14.3586 12.1414L9.5 17' stroke='white' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E");
          position: absolute;
          top: 5px;
          right: 8px;
          pointer-events: none;
          opacity: 50%;
        }

        a {
          width: 100%;

          &:hover {
            color: #fff;
          }
        }
      }
    }
  }
}
