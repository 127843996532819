.page.-purchase-complete-page {
  background: $grey1;

  > .max-width-wrapper > ._loader-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
  }

  // Titles
  > .max-width-wrapper > ._titles {
    margin: 34px 0 40px 0;

    > .page-heading-1 {
      margin: 0 0 16px 0;
    }

    > .page-heading-2 {
      margin: 0 0 16px 0;
    }

    > ._subtext {
      @include typography-desktop-text-x-small($grey5);

      > a {
        color: $primaryPink;
        @include hover-underline;
      }
    }
  }

  > .max-width-wrapper > ._content-wrapper {
    display: flex;
    margin-top: 34px;

    @include bp(1000px) {
      flex-direction: column;
    }

    > ._left-content {
      width: 100%;
      margin-right: 48px;

      @include bp(1200px) {
        margin-right: 36px;
      }

      @include bp($mobileBreakpoint) {
        margin: 0 auto;
      }
    }

    > ._right-content {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      min-width: 300px;

      @include bp(1000px) {
        margin-top: 40px;
      }
    }
  }
}
