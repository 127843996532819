.image {
  position: relative;

  &.-placeholder {
    width: 100%;
    height: 100%;
    background-color: $grey1;
    background-image: url(../../images/logos/arvutitark-white.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    border-radius: 8px;
  }
}
