.page.-customer-orders-page {
  .search-input-wrapper {
    display: flex;
    position: relative;
    padding: 8px 42px 8px 16px;
    background: #fff;
    border-radius: 4px;
    min-width: 300px;
    margin-right: 2px;

    &:focus-within,
    &:hover {
      box-shadow: 0px 0px 0px 2px rgba($primaryPink, 0.4);
      > svg {
        > path {
          stroke: $primaryPink;
        }
      }
    }

    > svg {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      width: 16px;
    }

    > input {
      @include typography-desktop-text-x-small;
      border: none;
      outline: none;
      width: 100%;
    }
  }
}
