.catalogue-product-price {
  white-space: nowrap;
  display: inline-flex;
  flex-wrap: wrap;

  @include bp($mobileBreakpoint) {
    &.-list ._main-price {
      font-size: 14px;
    }
  }

  ._main-price,
  ._old-price {
    display: inline-flex;
    height: 36px;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }

  ._main-price {
    @include inter(700);
    color: #ffffff;
    background: $primaryDarkBlue;
    border-radius: 8px;
    padding: 6px 12px;
    font-size: 20px;
    line-height: 24px;

    > span {
      margin-bottom: -1px;
    }

    &.-sale {
      color: #ffffff;
      background-color: $primaryPink;
    }

    .price-html-decimal {
      font-size: 14px;
    }
  }

  ._old-price {
    color: $grey4;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-decoration: line-through;

    @include bp($mobileBreakpoint) {
      font-size: 12px;
    }
  }
}
