.menu-item__icon,
.menu-item__text {
  display: flex;
  align-items: center;
  min-height: 24px;
  width: 100%;

  span {
    margin-right: 6px;

    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
  }

  .icon {
    margin-right: 16px;
    height: 24px;
    width: 24px;
  }

  &:hover {
    .icon,
    ._chevron {
      filter: $primaryPinkFilter;
    }
  }
}
