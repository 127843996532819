.basket-page-basket-content-sidebar {
  > ._top-section {
    padding: 32px 24px 24px 24px;
    position: relative;

    > ._loader-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(#fff, 0.92);

      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }

    > ._title {
      @include typography-desktop-text-large;
    }

    > ._products-list {
      list-style: none;
      padding: 0;
      margin: 24px 0 0 0;

      > ._products-list-item {
        display: flex;

        &:not(:last-child) {
          margin-bottom: 6px;
        }

        > ._name {
          @include inter(400);
          font-size: 16px;
          line-height: 24px;
          color: $primaryDarkBlue;
          flex: 1;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-right: 16px;
        }

        > ._price {
          @include inter(500);
          line-height: 24px;
          font-size: 14px;
          text-align: right;
          color: $grey5;
          white-space: nowrap;
        }
      }
    }

    ._splitter {
      width: 100%;
      height: 1px;
      background: $grey2;
      margin: 18px 0;
      @include bp(640px) {
        margin-left: -24px;
        width: calc(100% + 48px);
      }
    }

    > .basket-page-side-box-sum-rows {
      margin-bottom: 8px;
    }

    > .button {
      width: 100%;
    }

    > a > .button {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  ._checkboxes-wrapper {
    margin-top: 18px;
    border-top: 1px solid $grey2;
    padding: 16px 0;
  }

  > ._bottom-section {
    background: $grey1;
    flex: 1;
    padding: 24px;

    ._value {
      margin-top: 8px;
      @include inter(400);
      font-size: 16px;
      line-height: 24px;
      color: $grey5;

      > b {
        @include inter(700);
      }
    }
  }
}
