.category-page-campaign-banner {
  min-height: 94px;
  width: 100%;
  background: $primaryDarkBlue;
  border-radius: 8px;
  overflow: hidden;
  margin: 0 0 32px 0;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;

  ._title {
    @include typography-desktop-text-large($grey1);
    z-index: 1;
  }

  ._description {
    margin-top: 6px;
    @include typography-desktop-text-x-small($grey1);
    z-index: 1;
  }

  ._label {
    white-space: nowrap;
    align-self: flex-start;
    background: $primaryPink;
    border-radius: 100px;
    padding: 5px 8px;
    @include typography-label-caption($grey1);
    z-index: 1;

    &:hover {
      background-color: $primaryPinkHover !important;
    }
  }

  ._background-text {
    @include inter(700);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 60%;
    color: $grey5;
    font-size: 220px;
    line-height: 94px;
    opacity: 0.4;
    z-index: 0;
    user-select: none;
    white-space: nowrap;
    transition: transform 0.6s $transition;
    transform-origin: right bottom;
  }

  @include bp($mobileBreakpoint) {
    margin: 0;
    border-radius: 0;
    height: auto;
    min-height: 160px;
    padding: 24px;

    ._title {
      margin-top: auto;
    }

    ._label {
      top: 24px;
      right: 24px;
    }

    ._background-text {
      left: -20%;
      line-height: 160px;
    }
  }

  &:hover {
    ._background-text {
      transform: scale(1.05);
    }
  }
}
