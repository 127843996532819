.catalogue-product-wrapper.-compact .catalogue-product-price {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;

  > ._old-price {
    margin-right: 8px;
  }

  &.-inside {
    display: none;
  }

  @include bp($mobileBreakpoint) {
    &.-outside {
      display: none;
    }

    &.-inside {
      display: block;
      margin: 4px 0;

      ._main-price {
        &.-sale {
          margin-right: 6px;
        }
      }
    }
  }
}
