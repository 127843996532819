.radio-box {
  min-height: 64px;
  height: 64px;
  width: auto;
  min-width: 0;
  padding: 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $grey2;
  border-radius: 4px;
  overflow: hidden;
  transition: all 0.2s $transition;

  &:not(.-disabled):hover {
    box-shadow: 0px 0px 0px 2px rgba($primaryPink, 0.4);
    border-color: transparent;
  }

  &.-checked {
    box-shadow: 0px 0px 0px 3px rgba($primaryPink, 0.4);
    border-color: transparent;
  }

  &.-has-small-img > label._radio-wrapper {
    padding-left: 30px;
  }

  > label._radio-wrapper {
    display: flex;
    align-items: center;

    > ._small-img {
      max-width: 80px;
      max-height: 24px;
      margin-right: 12px;
      pointer-events: none;
    }

    > ._img {
      max-height: 50px;
      max-width: 122px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
