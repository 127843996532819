.page.-content-page {
  ._wrapper {
    display: flex;
    padding-bottom: 70px;

    @include bp(900px) {
      flex-direction: column;
    }

    > ._sections {
      flex: 1;
    }
  }

  ._image-wrapper {
    max-width: 100%;
    display: flex;
    margin: 32px 0;

    > img {
      object-fit: cover;
      max-width: 100%;
      width: 100%;
      height: 100%;
    }
  }

  ._flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 16px;
  }

  ._text {
    margin-bottom: 32px;

    &:not(:first-child) {
      margin-top: 32px;
    }

    @include bp($mobileBreakpoint) {
      margin-bottom: 24px;

      &:not(:first-child) {
        margin-top: 24px;
      }
    }

    p,
    span,
    li {
      @include typography-desktop-text-x-small($primaryDarkBlue);
      font-weight: 400;
    }

    a {
      cursor: pointer;
      color: $primaryPink;
      @include hover-underline;
    }

    img {
      max-width: 100%;
    }
  }
}
