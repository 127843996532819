.carousel {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .carousel__content__static-wrapper {
    overflow: hidden;

    .carousel__content {
      display: flex;
      transform: translateZ(0);
      flex-grow: 1;
      height: 100%;

      .carousel__slot {
        width: 100%;
        flex: 1 0 100%;
      }
    }
  }
}
