.box-wrapper {
  border-radius: 8px;
  padding: 32px;
  background: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @include box-shadow-full;
  margin-bottom: 24px;

  @include bp(500px) {
    padding: 16px;
  }

  &.-placeholder {
    align-items: center;
    justify-content: center;
  }
}
