section.section {
  margin-top: 56px;

  @include bp($mobileBreakpoint) {
    margin-top: 42px;
  }

  > ._title {
    @include typography-desktop-text-large;
    margin-bottom: 16px;
  }
}
