.category-page-mobile-menu {
  margin-bottom: 20px;

  ._menu-header {
    display: flex;
    margin-bottom: 4px;
    position: relative;

    .category-menu {
      width: 100%;
      overflow: hidden;
    }

    ._dropdown-trigger {
      margin: 0;
      display: flex;
      align-items: center;
      padding: 0 24px;
      overflow: hidden;
      height: 40px;
      border-radius: 4px;
      background-color: $grey1;
      border: 1px solid transparent;

      ._chevron {
        margin-left: auto;
        transform: rotate(90deg);
        transition: transform 0.5s;
      }

      ._menu-item.-active {
        @include typography-desktop-text-small;
        margin-right: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.-open {
        border-radius: 4px 4px 0 0;
        ._chevron {
          transform: rotate(-90deg);
        }
      }
    }

    ._parent-category-link {
      margin-right: 8px;
      height: 40px;

      ._parent-category-chevron {
        border-radius: 4px;
        padding: 0 24px;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        background-color: $grey1;
      }
    }

    ._menu-dropdown {
      transition: max-height 0.5s;
      max-height: 0;
      overflow: hidden;
      border-radius: 0 0 4px 4px;
      background-color: $grey1;
      transition: max-height 400ms ease;

      &.-open {
        max-height: 300px;
        overflow-y: scroll;
        opacity: 1;
      }

      ._menu-items {
        display: flex;
        flex-direction: column;
      }

      ._menu-item {
        padding: 6px 24px;
        @include inter;
        font-size: 16px;
        line-height: 24px;
        color: $primaryDarkBlue;

        &:first-of-type {
          padding-top: 0px;
        }

        &.-active {
          color: $primaryPink;
        }
      }
    }
  }
}
