.form-status {
  padding: 12px;
  border: 1px solid transparent;
  margin-bottom: 8px;
  border-radius: 4px;
  color: #fff;

  &.-success {
    background: #75ba01;
    border-color: #fff;
  }

  &.-error {
    background-color: $primaryPink;
    border-color: #fff;
  }
}
