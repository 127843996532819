.choose-role-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 26px 0;
  overflow: hidden;

  > ._title {
    @include typography-desktop-text-large;
    color: $primaryBlack;
    margin-bottom: 18px;
  }

  > ._options {
    max-width: 90%;
    display: flex;
    flex-direction: column;
    position: relative;

    @include bp($mobileBreakpoint) {
      width: 100%;
      max-width: calc(100% - 32px);
      overflow: hidden;
    }

    > ._role-option {
      display: flex;
      height: 64px;
      background: $grey1;
      min-width: 325px;
      margin-bottom: 8px;
      border-radius: 8px;
      overflow: hidden;
      transition: all 0.2s $transition;

      @include bp($mobileBreakpoint) {
        width: 100%;
        min-width: 100%;
      }

      > ._icon-wrapper {
        height: 100%;
        padding: 0 16px 0 21px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      > ._content-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;

        > ._type {
          @include typography-label-caption;
          color: $primaryBlack;
        }

        > ._name {
          @include inter(500);
          color: $primaryBlack;
          font-size: 16px;
          line-height: 24px;
          white-space: nowrap;
          transition: all 0.2s $transition;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      > ._arrow-wrapper {
        margin-left: auto;
        padding: 0 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          height: 16px;
          width: 16px;

          path {
            stroke: $primaryBlack;
          }
        }
      }

      &:hover {
        cursor: pointer;
        opacity: 0.8;

        ._name {
          color: $primaryPink;
        }
      }
    }

    > ._loader-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: rgba(#fff, 0.8);
      z-index: 10;

      > .loader {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
      }
    }
  }
}
