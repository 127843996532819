.product-page-price-basket-info-area {
  background: #fff;
  padding: 37px 24px 32px 24px;

  @include bp($mobileBreakpoint) {
    padding: 37px 0 32px 0;
  }

  > .-price-and-avail {
    .-availability-link {
      button {
        @include hover-underline;
        color: $primaryPink;
      }
    }
  }

  ._box {
    &.-monthly-payment-box {
      > ._value {
        > ._price {
          @include inter(400);
          font-size: 14px;
          line-height: 24px;

          > span {
            @include inter(600);
            font-size: 24px;
            line-height: 32px;
            color: $primaryDarkBlue;
          }
        }

        > ._calculate-button-wrapper {
          display: flex;

          > ._calculate-button {
            display: flex;
            align-items: center;
            margin-right: auto;
            border: none;
            padding: 0;
            background: none;

            &:hover {
              cursor: pointer;

              > svg > path {
                fill: $primaryPink;
              }
            }

            > svg {
              margin-right: 4px;
            }

            > span {
              @include typography-desktop-link-x-small($hover: $primaryPink);
            }
          }
        }
      }
    }
  }
}
