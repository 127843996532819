div.modal .login-modal {
  width: 1224px;
  height: 800px;
  display: flex;

  @include bp($mobileBreakpoint) {
    height: auto;
  }

  ._close-btn {
    @include minbp($tabletBreakpoint) {
      top: 32px;
      right: 32px;
    }
  }

  .left-panel {
    background-color: #14142a;
    padding: 12% 12px 0 64px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @include bp($tabletBreakpoint) {
      display: none;
    }

    @include hp(600) {
      display: none;
    }

    .-intro {
      @include typography-desktop-text-large($grey1);
      margin-bottom: 32px;
    }

    > img {
      max-width: 180px;
    }
  }

  .user-login-tabs .tabs {
    @include bp($tabletBreakpoint) {
      padding: 40px 16px;
    }
  }
}
