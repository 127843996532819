.-shops-page.city-tabs {
  .tab-buttons {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid $grey2;
    margin-bottom: 38px;
    overflow-x: auto;
  }

  .tab-button-wrapper {
    margin-right: 56px;
    position: relative;

    @include bp(1000) {
      margin-right: 12px;
    }

    .tab-button {
      display: flex;
      align-items: center;

      &:hover {
        ._city,
        ._count {
          color: $primaryPink;
        }
      }

      ._city {
        @include typography-desktop-text-large;
      }

      ._count {
        @include typography-desktop-text-x-small($grey5);
        margin-left: 4px;
      }
    }

    &.-active {
      &:after {
        content: ' ';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        width: 100%;
        background-color: $primaryPink;
        border-radius: 100px 100px 0px 0px;
      }
    }
    &:hover {
      &:after {
        content: ' ';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        height: 4px;
        width: 100%;
        background-color: $primaryPink;
        border-radius: 100px 100px 0px 0px;
      }
    }
  }

  .shop-location-image {
    margin-bottom: 32px;

    @include bp($mobileBreakpoint) {
      margin-bottom: 0;
    }

    img {
      display: flex;
      height: 100%;
      width: 100%;
    }
  }

  ._shops {
    display: flex;
    flex-wrap: wrap;
    margin: 0 12px;

    @include bp(1000) {
      margin: 0;
    }

    ._shop {
      $width: calc(50% - 35px);

      max-width: $width;
      width: $width;
      position: relative;
      margin-bottom: 70px;

      border-radius: 8px;
      padding: 24px;
      background-color: $grey1;

      &:nth-of-type(even) {
        margin-left: 70px;
      }

      @include bp(1000) {
        width: 100%;
        max-width: 100%;
        margin-bottom: 42px;

        &:nth-of-type(even) {
          margin-left: 0;
        }
      }
      @include bp($mobileBreakpoint) {
        &:last-child {
          margin-bottom: 22px;
        }
      }
    }

    ._shop-info {
      // Align images and info on same line
      margin: 8px;

      ._name {
        @include typography-desktop-text-large;
      }

      ._contact-rows {
        margin-top: 16px;

        ._important {
          color: $primaryPink;
          font-weight: 600;
        }
        ._link {
          color: $primaryPink;
        }

        .contact-info__row-title {
          @include typography-desktop-text-small;
        }
      }
    }

    ._gallery-images {
      position: relative;
      display: flex;
      max-height: 200px;
      height: 200px;
      width: 100%;
      overflow: hidden;

      > ._image-wrapper {
        min-width: calc(50%);
        max-height: calc(100%);
        max-width: calc(100%);
      }

      ._image-wrapper {
        flex: 1;
        position: relative;
        padding: 8px;
        display: flex;
        cursor: pointer;
      }

      ._image {
        flex: 1;
        display: flex;
        border-radius: 4px;
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        cursor: pointer;
      }

      ._image-grid {
        display: flex;
        flex-wrap: wrap;
        max-height: 100%;
        position: relative;

        ._image-wrapper {
          flex: 1 1 50%;
          max-height: 50%;
          // min-width: 120px;

          ._image-overlay {
            position: absolute;
            border-radius: 4px;
            top: 8px;
            left: 8px;
            right: 8px;
            bottom: 8px;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.31), rgba(0, 0, 0, 0.31));

            span {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              @include typography-desktop-text-large(#fff);
            }
          }

          &:only-child {
            max-height: calc(100%);
          }

          // When only two grid images underline them
          &:first-child:nth-last-of-type(2) {
            flex-basis: auto;
          }
        }
      }
    }
  }
}
