.verify-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 26px 0;

  > ._title {
    @include typography-desktop-text-large;
    color: $primaryBlack;
    margin-bottom: 18px;
  }

  > ._notice {
    max-width: 75%;
  }

  > ._button {
    margin-top: 24px;
  }
}
