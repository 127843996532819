.searchable-select-wrapper {
  .searchable-select {
    background: $grey1;
    height: 64px;
    border-radius: 4px;
    border: 2px solid transparent;
    padding: 11px 24px;
    position: relative;
    transition: all 300ms $transition;
    cursor: text;

    > .searchable-select__value {
      cursor: text;
      width: 100%;

      > label {
        position: absolute;
        transition: all 300ms $transition;
        top: 11px;
        left: 24px;

        @include typography-label-caption;
        text-transform: uppercase;
        height: 14px;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        max-width: calc(100% - 48px - 28px);
      }

      > input {
        position: absolute;
        bottom: 11px;
        left: 24px;
        right: 24px;
        top: 27px;
        width: calc(100% - 48px - 28px);
        background: none;
        border: none;
        outline: none;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        // Remove default padding
        padding: 0;

        @include typography-desktop-text-x-small;
      }
    }

    &.-error {
      border-color: $primaryPink;
    }

    &.-disabled {
      opacity: 0.6;

      > *,
      > * > * {
        cursor: not-allowed;
      }
    }

    &.-required {
      label::after {
        content: '*';
      }
    }

    &.-no-label {
      display: flex;

      > .searchable-select__value {
        display: flex;
        align-items: center;

        > input {
          position: relative;
          width: 100%;
          bottom: 0;
          right: 0;
          left: 0;
          top: 0;
          color: $primaryDarkBlue;
          padding: 0 8px 0 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      > ._dropdown-chevron {
        right: 14px;
      }
    }

    &:not(.-multiline-options) > .searchable-select__select {
      .searchable-select__options {
        .searchable-select__option {
          // single line styles
          height: 36px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0px 16px;
        }
      }
    }

    > .searchable-select__select {
      position: absolute;
      z-index: 2;
      top: 58px;
      right: -2px;
      left: -2px;
      border-radius: 0 0 4px 4px;
      overflow: auto;
      max-height: 260px;
      background: #fff;
      border-top: 2px solid $primaryPink;
      @include box-shadow-full;

      .searchable-select__options {
        list-style: none;
        padding: 0;
        margin: 0;

        .searchable-select__group-header {
          @include typography-label-caption($primaryBlack);
          background: $grey1;
          padding: 6px 16px;
          border-bottom: 1px solid $grey1;
        }

        .searchable-select__row:not(:first-child) {
          border-top: 1px solid $grey1;
        }

        .searchable-select__option,
        .searchable-select__not-found {
          display: block;
          background: #fff;
          width: 100%;
          padding: 5px 16px;
          border: none;
          outline: none;
          text-align: left;
          cursor: pointer;

          font-size: 14px;
          line-height: 36px;
          color: $primaryBlack;
          @include typography-desktop-text-x-small;

          &:disabled {
            color: $grey3;
            cursor: not-allowed;
          }
        }

        .searchable-select__option {
          &.is-highlighted.is-selected,
          &.is-selected:hover {
            background: rgba($primaryPink, 0.8);
            color: #fff;
          }

          &.is-selected {
            background: $primaryPink;
            font-weight: 600;
            color: #fff;
          }

          &.is-highlighted,
          &:not(.is-selected):hover {
            background: rgba($primaryPink, 0.1);
          }
        }

        .searchable-select__not-found {
          height: auto;
          padding: 16px;
          text-align: center;
          color: #888;
        }
      }
    }

    > ._dropdown-chevron {
      position: absolute;
      right: 30px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 14px;
      transition: transform 0.2s $transition;
    }

    &.-focus {
      > ._dropdown-chevron {
        transform: rotate(270deg) !important;
      }
    }

    &.-empty.-blur {
      > .searchable-select__value {
        > label {
          top: 25px;
          color: $grey3;
        }
      }
    }

    &.-focus {
      border-color: $primaryPink;
      background: #fff;

      > .searchable-select__value {
        > label {
          top: 11px;
        }
      }
    }
  }

  > ._error {
    @include typography-error-message-desktop;
  }
}
