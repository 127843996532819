.contact-info__row-title {
  min-width: 200px;
  max-width: 200px;
  margin-right: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-info__row-item {
  @include typography-desktop-text-x-small($grey5);
  font-weight: 400;
  ._link {
    color: $primaryPink;
    @include hover-underline;
  }

  > .wysiwyg > div:first-child {
    margin: 0;
  }

  .wysiwyg a {
    color: $primaryPink;
    @include hover-underline;
  }
}

.contact-info__row {
  display: flex;
  margin-bottom: 16px;

  @include bp($mobileBreakpoint) {
    flex-direction: column;
    margin-bottom: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
