.catalogue-product-wrapper.-grid {
  display: flex;
  flex-direction: column;
  min-width: 170px;

  .catalogue-product {
    display: block;
    overflow: hidden;
    margin-bottom: 10px;

    > ._body {
      flex-basis: 50%;
      flex-grow: 1;
      display: flex;
      flex-direction: column-reverse;

      ._name-wrapper {
        margin: 10px 0;
      }

      @include bp(600px) {
        flex-direction: column;
      }
    }

    ._image-wrapper {
      height: 144px;
      height: 144px;
      margin-right: 16px;
      margin-bottom: 16px;
      position: relative;

      img {
        object-fit: contain;
        object-position: center;
        max-width: 100%;
        height: 100%;
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  ._fav-button-desktop {
    display: block;
  }
  ._fav-button-mobile {
    display: none;
  }

  @include bp(600px) {
    .catalogue-product {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
    }

    ._fav-button-desktop {
      display: none;
    }
    ._fav-button-mobile {
      display: block;
    }

    ._image-wrapper {
      height: 144px;
      width: 144px;
    }

    ._body {
      margin-top: 0;

      > ._name-wrapper {
        > ._name {
          -webkit-line-clamp: 2;
        }
      }
    }

    .product-main-attributes {
      flex-basis: 100%;
      overflow: hidden;
      margin-top: 8px;
    }

    .add-to-cart-btn.-mobile {
      display: initial;
    }
  }

  .add-to-cart-btn.-hoverable {
    display: none;
  }

  @media (hover) {
    &::after {
      content: '';
      display: block;
      background-color: #ffffff;
      box-shadow: $box-shadow-full;
      top: 0px;
      bottom: 0px;
      right: 0px;
      left: 0px;
      position: absolute;
      z-index: -1;
      opacity: 0;
      transition: all 0.2s $transition;
      border-radius: 4px;
    }

    @include minbp(600px) {
      .add-to-cart-btn.-hoverable {
        visibility: hidden;
        display: initial;
      }
      .add-to-cart-btn.-mobile {
        display: none;
      }
    }

    &:hover {
      z-index: 15;

      &::after {
        opacity: 1;
        top: -24px;
        bottom: -24px;
        right: -24px;
        left: -24px;
      }

      @include bp(600px) {
        &::after {
          display: none;
        }
      }

      .add-to-cart-btn.-hoverable {
        visibility: visible;
        opacity: 0;
        animation-name: fade-btn-in;
        animation-fill-mode: forwards;
        animation-play-state: running;
        animation-duration: 0.2s;
      }

      @keyframes fade-btn-in {
        0% {
          opacity: 0;
          transform: scale(0.6);
        }

        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
    }
  }
}
