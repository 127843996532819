@keyframes breathe {
  0%,
  80%,
  100% {
    box-shadow: 0 10px 0 -5px;
  }
  40% {
    box-shadow: 0 10px 0 0;
  }
}

.generic-loader {
  width: 64px;
  margin: 0 auto;
  padding: 20px 0;
  overflow: hidden;

  .loader,
  .loader:before,
  .loader:after {
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation-fill-mode: both;
    animation: breathe 1.5s infinite ease-in-out;
  }

  .loader {
    color: $primaryBlack;
    font-size: 10px;
    margin: 10px auto;
    position: relative;
    top: -2px;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.35s;
  }

  .loader:before,
  .loader:after {
    content: '';
    position: absolute;
    top: 0;
  }

  .loader:before {
    left: -25px;
    animation-delay: -0.7s;
  }

  .loader:after {
    left: 25px;
  }
}
