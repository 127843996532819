.basket-page-side-box-sum-rows {
  margin: 12px 0 0 0;
  padding: 0;

  > ._order-row {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 13px;

    > ._label {
      @include typography-label-caption;
      display: flex;
      align-items: center;

      > ._remove-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 14px;
        width: 14px;
        margin-left: 4px;
        cursor: pointer;

        > svg {
          height: 10px;
          width: 10px;
        }

        > svg > path {
          fill: $primaryPink;
          cursor: pointer;
        }
      }
    }

    > ._value {
      @include inter(500);
      font-size: 14px;
      text-align: right;
      color: $grey5;
      margin-left: auto;
      white-space: nowrap;

      &.-pink {
        color: $primaryPink;
      }

      > ._btn {
        color: $primaryPink;
        cursor: pointer;
        @include hover-underline;
      }
    }

    &.-summary {
      > ._value {
        color: $primaryPink;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
