div.modal .catalogue-filters-modal {
  max-height: 100%;
  height: 100%;
  border-radius: 0;
  position: relative;
  padding: 16px 16px 64px;
  overflow-y: auto;
  touch-action: pan-y;

  @include bp($mobileBreakpointCatalogue) {
    width: 100%;
    max-width: 100%;
    border-radius: 8px 8px 0 0;
  }

  .filter-modal-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    padding: 16px;
    z-index: 10;

    .button {
      @include typography-mobile-text-small(#ffffff);
      flex: 1;
      height: 40px;

      &:first-of-type {
        margin-right: 8px;
      }
    }
  }
}
