.range-slider-input {
  @include bp(900px) {
    padding: 0 16px;
  }

  > ._input-bubbles {
    display: flex;
    margin-bottom: 14px;

    > ._bubble {
      padding: 6px 8px;
      background: rgba($grey2, 0.5);
      border-radius: 32px;
      max-width: 100px;
      display: flex;
      align-items: center;
      overflow: hidden;

      > input {
        width: 100%;
      }

      > span,
      input {
        @include inter(700);
        color: $grey4;
        font-size: 12px;
        text-align: right;
        margin: 0;
        padding: 0;
      }

      input {
        background: none;
        border: none;
        outline: none;
      }
    }
  }

  .slider {
    position: relative;
    margin: 0 10px;
    height: 16px;
    user-select: none;

    > ._rail {
      height: 8px;
      border-radius: 40px;
      background: linear-gradient(
        to bottom,
        transparent calc(50% - 1px),
        $grey2 calc(50% - 1px),
        $grey2 calc(50% + 1px),
        transparent calc(50% + 1px)
      );
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    > ._handles {
      > ._handle {
        position: absolute;
        top: 0;
        height: 16px;
        width: 16px;
        outline: 2px solid #fff;
        background: $primaryPink;
        border-radius: 50%;
        margin-left: -8px;
        z-index: 5;
        cursor: pointer;
        touch-action: pan-x;

        @include bp(900px) {
          height: 24px;
          width: 24px;

          margin-left: -16px;
          margin-top: -4px;

          &:first-of-type {
            margin-left: -8px;
          }
        }

        &:hover {
          opacity: 0.9;
          transform: scale(1.1);
        }
      }
    }

    > ._tracks {
      > ._track {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;

        height: 8px;
        z-index: 1;
        border-radius: 10px;
        background: linear-gradient(
          to bottom,
          transparent calc(50% - 2px),
          $primaryPink calc(50% - 2px),
          $primaryPink calc(50% + 2px),
          transparent calc(50% + 2px)
        );
      }
    }
  }
}
