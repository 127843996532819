@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbar {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .x-scroll-fade-left {
    /* disable overflow, but allow scrolling with no scrollbar */
    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%);
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 5%);
    @apply no-scrollbar;
  }

  .x-scroll-fade-right {
    -webkit-mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0));
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 95%, rgba(0, 0, 0, 0));
    @apply no-scrollbar;
  }

  .x-scroll-fade-both {
    -webkit-mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 5%,
      rgba(0, 0, 0, 1) 95%,
      rgba(0, 0, 0, 0)
    );
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 5%,
      rgba(0, 0, 0, 1) 95%,
      rgba(0, 0, 0, 0)
    );

    @apply no-scrollbar;
  }

  .anchored-element {
    display: none;
  }
  @supports (anchor-name: --foo) {
    .anchored-element {
      display: initial;
    }
  }
}

@layer components {
  .font-display {
    @apply font-normal;
  }

  .zoom-50 {
    zoom: 50%;
  }

  .zoom-75 {
    zoom: 75%;
  }

  .font-label {
    @apply text-label uppercase text-grey-5;
  }

  .fwh:focus-within {
    @apply border border-dashed border-primary-pink;
  }

  .filter-primary-pink {
    filter: invert(32%) sepia(88%) saturate(7145%) hue-rotate(311deg) brightness(87%) contrast(109%);
  }

  .filter-grey-2 {
    filter: brightness(0) saturate(100%) invert(92%) sepia(10%) saturate(398%) hue-rotate(198deg) brightness(95%)
      contrast(89%);
  }

  .filter-utility-black {
    filter: brightness(0) saturate(100%) invert(2%) sepia(93%) saturate(2815%) hue-rotate(235deg) brightness(96%)
      contrast(101%);
  }

  .bg-dark-striped {
    background-color: #14142b;
    background-image: repeating-linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.05),
      rgba(255, 255, 255, 0.05) 1px,
      transparent 2px,
      transparent 17px
    );
  }

  .bg-fade-out {
    -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.4) 1.5rem, rgba(0, 0, 0, 1) 3rem);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.4) 1.5rem, rgba(0, 0, 0, 1) 3rem);
  }
}

/* Zendesk below toasts & basket */
.zopim {
  z-index: 99 !important;
}
