div.box-grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -24px; // Counteract the 24px bottom margin set by the last row of items

  .box-grid-item {
    flex-basis: 33%;
    margin-bottom: 24px;
    padding-right: 12px;

    @include bp($mobileBreakpoint) {
      flex-basis: 50%;
    }

    > .label {
      @include typography-label-caption();
      margin-bottom: 4px;
    }

    > .text {
      @include typography-desktop-text-x-small($grey5);
    }
  }
}
