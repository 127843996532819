.campaign-page-modular-disclaimer-section {
  display: flex;

  @include bp($mobileBreakpoint) {
    flex-direction: column;
  }

  &.-no-image {
    > ._text-content {
      max-width: 800px;
    }
  }

  &.-image {
    > ._text-content {
      margin-left: 120px;

      @include bp($mobileBreakpoint) {
        margin-left: 0;
        margin-top: 24px;
      }
    }
  }

  > ._image {
    max-width: 232px;
    overflow: hidden;

    > img {
      object-fit: contain;
      object-position: center;
      width: 100%;
    }
  }

  > ._text-content {
    flex: 1;
    width: 100%;
    max-width: 630px;

    &,
    > *,
    > * > * {
      @include typography-desktop-text-x-small($grey4);
    }
  }
}
