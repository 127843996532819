@use 'sass:math';

// Core
@import './config';
@import './core/main';
@import './core/typography';
@import './core/css-animations';

// Wysiwyg
@import '../../public/css/wysiwyg.css';

// Packages
@import 'react-day-picker/lib/style.css';

// Pages
@import './pages/basket-page';
@import './pages/home-page';
@import './pages/content-page';
@import './pages/checkout-page';
@import './pages/purchase-complete-page';
@import './pages/order-tracking-page';
@import './pages/create-support-case-page';
@import './pages/support-case-tracking-page';
@import './pages/reset-password-form-page';

// Pages -- Account
@import './pages/account/dashboard-page';
@import './pages/account/customer-data-page';
@import './pages/account/orders-page';
@import './pages/account/authorizations-page';

// Components
@import './components/image';
@import './components/button';
@import './components/toasts';
@import './components/tooltip';
@import './components/section';
@import './components/lightbox';
@import './components/paginator';
@import './components/breadcrumbs';
@import './components/quantity-selector';
@import './components/checkout-breadcrumbs';
@import './components/product-main-attributes';
@import './components/side-box-section-info-box';
@import './components/sidebar-menu';
@import './components/main-nav-menu';
@import './components/dropdown';
@import './components/headings';
@import './components/contact-info';
@import './components/react-day-picker';
@import './components/simplebar';
@import './components/footer/footer';
@import './components/leaflet';

// -- Carousel
@import './components/campaigns/campaign-banner';
@import './components/campaigns/campaign-box';

// -- Modal
@import './components/modal/modal';
@import './modal/login-modal';
@import './modal/confirmation-modal';
@import './modal/part-payment-modal';
@import './modal/catalogue-filters-modal';
@import './modal/new-authorization-modal';

// -- Product page
@import './components/product-page/product-page-price';
@import './components/product-page/product-page-price-box';
@import './components/product-page/product-page-availability-info-box';
@import './components/product-page/product-page-price-basket-info-area';

// -- Services & Warranty
@import './components/services/product-services-area';

// -- Campaign page
@import './components/campaign-page/campaign-page-modular-banner-section';
@import './components/campaign-page/campaign-page-modular-products-section';
@import './components/campaign-page/campaign-page-modular-disclaimer-section';
@import './components/campaign-page/campaign-page-modular-image-text-section';

// -- Basket page
@import './components/basket-page/basket-page-side-box-sum-rows';
@import './components/basket-page/basket-page-basket-content-sidebar';

// -- Carousel
@import './components/carousel/carousel';
@import './components/carousel/product-carousel';

// -- Catalogue
@import './components/catalogue/grid/catalogue-product-grid';
@import './components/catalogue/list/catalogue-product-list';
@import './components/catalogue/catalogue-product';
@import './components/catalogue/catalogue-product-price';
@import './components/catalogue/catalogue-header';
@import './components/catalogue/catalogue-filters';
@import './components/catalogue/list/catalogue-product-price-list';
@import './components/catalogue/list/product-main-attributes-list';
@import './components/catalogue/grid/catalogue-product-price-grid';

// -- Category page
@import './components/category-page/category-page-menu';
@import './components/category-page/category-page-seo-section';
@import './components/category-page/category-page-mobile-menu';
@import './components/category-page/category-page-campaign-banner';

// -- Content page
@import './components/content-page/content-page-contact-section';

// -- Checkout page
@import './components/checkout-page/checkout-page-customer-info-form-section';

// -- Shops page
@import './components/shops-page/shops-page-info-blocks';
@import './components/shops-page/shops-page-city-tabs';

// -- Input
@import './components/input/select';
@import './components/input/radio';
@import './components/input/checkbox';
@import './components/input/text-input';
@import './components/input/radio-box';
@import './components/input/searchable-select';
@import './components/input/formik-status';
@import './components/input/toggle';
@import './components/input/slider';
@import './components/input/range-slider';

// -- Loaders
@import './components/loader/loader';
@import './components/loader/generic-loader';
@import './components/loader/loader-with-wrapper';

// -- Filters
@import './components/filters/active-filters';
@import './components/filters/filters-selected-count';
@import './components/filters/price-input-field';
@import './components/filters/show-more-filters';
@import './components/filters/availability-filter';

// -- Menu
@import './components/menu/menu-item-hover-menu';
@import './components/menu/menu-item-components/menu-item-icon';

// -- Order box
@import './components/order-box/order-box-action-buttons';

// -- Tabs
@import './components/tabs/tab-button';

// -- Account
@import './components/account/user-login-tabs';
@import './components/account/login-form';
@import './components/account/forgot-password-form';
@import './components/account/register-form';
@import './components/account/account-navigation-dropdown';
@import './components/account/account-navigation-menu';
@import './components/account/account-header';
@import './components/account/account-layout';
@import './components/account/account-section';
@import './components/account/account-table';
@import './components/account/login-box';
@import './components/account/choose-role';
@import './components/account/verify-email';

// -- Support case
@import './components/support-case/create-new-case-button';

// -- Box
@import './components/box/box';
@import './components/box/box-dates';
@import './components/box/box-table';
@import './components/box/box-grid';
@import './components/box/box-tracking';
