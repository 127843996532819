.sidebar-menu {
  $defaultTitleHeight: 55px;
  $activeTitleHeight: 119px;
  $fDur: 400ms;
  $hDur: 200ms;
  $qDur: 100ms;

  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  color: $primaryDarkBlue;

  > ._background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5001;
    background: rgba(0, 0, 0, 0.2);
  }

  > ._content {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 5002;

    height: 100%;
    width: 75vw;
    max-width: 600px;
    min-width: 325px;

    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $primaryDarkBlue;
    -webkit-overflow-scrolling: touch;

    display: flex;
    flex-direction: column;
    flex: 1;

    > ._header {
      z-index: 1;
      position: relative;
      background: linear-gradient(270deg, rgba($primaryPink, 0.4) 0%, transparent 100%);
      height: 113px;
      min-height: 113px;
      padding: 0 24px 24px;
      margin-bottom: -24px;
      display: flex;
      align-items: center;

      backface-visibility: hidden;
      transform: translateZ(0);

      ._close-btn {
        top: 16px;
        right: 16px;
        border: none;
        cursor: pointer;
        background-color: transparent;
        margin-left: auto;
      }
    }

    > ._menu-body {
      z-index: 2;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: white;
      border-top-right-radius: 24px;
      padding-top: 24px;

      > ._default-title {
        height: $defaultTitleHeight;
        @include typography-desktop-text-large;
        padding: 15px 24px 0 24px;
        margin-bottom: 16px;

        backface-visibility: hidden;
        transform: translateZ(0);
      }

      > ._menu-title-area {
        height: $activeTitleHeight;
        background: $grey1;
        padding: 24px 28px 20px 28px;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        margin-top: -24px;
        border-top-right-radius: 24px;

        backface-visibility: hidden;
        transform: translateZ(0);

        > ._back-btn {
          display: flex;
          align-items: center;

          background: none;
          border: none;
          @include inter(600);
          font-size: 16px;
          color: $primaryPink;
          margin-bottom: auto;

          > svg {
            margin-right: 8px;

            path {
              stroke: $primaryPink;
            }
          }
        }

        > ._title {
          display: flex;
          align-items: center;

          > span {
            @include typography-desktop-text-large($primaryDarkBlue);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          > img {
            height: 24px;
            width: 24px;
            margin-right: 8px;
          }
        }
      }

      > ._menu-items-list {
        display: flex;
        flex-direction: column;
        padding: 0 26px;
        width: 100%;
        flex: 1;
        margin: 0;

        > .menu-item {
          display: flex;
          align-items: center;
          width: 100%;
          margin-bottom: 32px;

          &.-title {
            font-weight: 600;
            margin-bottom: 24px;
          }

          > * {
            width: 100%;
          }

          &:not(.menu-item-type--text) {
            &:hover {
              color: $primaryPink;
            }
          }

          ._chevron {
            height: 14px;
            margin-left: auto;

            > path {
              stroke: $primaryDarkBlue;
            }
          }
        }

        // Locales
        + ._locale-picker-menu-item {
          color: $primaryPink;
          cursor: pointer;
          margin-bottom: 32px;
          margin-top: auto;
          font-weight: bold;
          padding: 0 26px;
        }
      }
    }
  }

  // ------------------------------
  // -- Animations
  // ------------------------------

  // Sidebar animations
  &.enter {
    > ._content {
      opacity: 0.8;
      transform: translateX(-100%);
    }

    > ._background {
      opacity: 0;
    }
  }

  &.enter-active {
    > ._content {
      opacity: 1;
      transform: translateX(0);
      transition: all $fDur $transition;
    }

    > ._background {
      opacity: 1;
      transition: all $fDur $transition;
    }
  }

  &.exit {
    > ._content {
      opacity: 1;
      transform: translateX(0);
    }

    > ._background {
      opacity: 1;
    }
  }

  &.exit-active {
    > ._content {
      opacity: 0;
      transform: translateX(-100%);
      transition: all $fDur $transition;
    }

    > ._background {
      opacity: 0;
      transition: all $fDur $transition;
    }
  }

  // Content animations
  > ._content {
    // Forwards animations (shared)
    > ._menu-body {
      &.enter {
        position: relative;
        z-index: 5;

        > ._menu-items-list {
          transform: translateX(100%);
          opacity: 0;
        }

        > ._locale-picker-menu-item {
          opacity: 0;
          transform: translateX(-100%);
        }
      }

      &.enter-active {
        position: relative;
        z-index: 5;

        > ._menu-items-list {
          transform: translateX(0);
          opacity: 1;
          transition: all $fDur $transition;
        }

        > ._locale-picker-menu-item {
          opacity: 1;
          transform: translateX(0);
          transition: all $fDur $transition;
        }
      }

      &.exit {
        position: absolute;
        top: 89px;
        z-index: 0;
        height: calc(100% - 89px);

        > ._menu-items-list {
          transform: translateX(0);
          opacity: 1;
          transition: all $fDur $transition;
        }

        > ._locale-picker-menu-item {
          transform: translateX(0);
          opacity: 1;
        }
      }

      &.exit-active {
        position: absolute;
        top: 89px;
        z-index: 0;
        height: calc(100% - 89px);

        > ._menu-items-list {
          transform: translateX(-100%);
          opacity: 0;
          transition: all $fDur $transition;
        }

        > ._locale-picker-menu-item {
          opacity: 0;
          transform: translateX(-100%);
          transition: all $fDur $transition;
        }
      }
    }

    // Forwards animations (root)
    &.-forwards.-root > ._menu-body {
      &.enter {
        > ._menu-title-area {
          height: $defaultTitleHeight;
          background: rgba($grey1, 0);

          > * {
            opacity: 0;
          }
        }
      }

      &.enter-active {
        > ._menu-title-area {
          height: $activeTitleHeight;
          background: rgba($grey1, 1);
          transition: all $fDur $transition;

          > * {
            opacity: 1;
            transition: all $hDur $transition $hDur;
          }
        }
      }

      &.exit {
        > ._default-title {
          height: $defaultTitleHeight;

          > * {
            opacity: 1;
          }
        }
      }

      &.exit-active {
        > ._default-title {
          height: $activeTitleHeight;
          transition: all $fDur $transition;

          > * {
            opacity: 0;
            transition: all $qDur $transition;
          }
        }
      }

      &.exit-done {
        > ._default-title {
          opacity: 0;
          transition: none;
          visibility: hidden;
        }
      }
    }

    // Forward animations (deep)
    &.-forwards.-deep > ._menu-body {
      &.enter {
        > ._menu-title-area {
          opacity: 0;

          > ._title {
            transform: translateX(100%);
          }
        }
      }

      &.enter-active {
        > ._menu-title-area {
          opacity: 1;
          transition: all $fDur $transition;

          > ._title {
            transform: translateX(0);
            transition: all $fDur $transition;
          }
        }
      }

      &.exit {
        > ._menu-title-area {
          > ._title {
            transform: translateX(0);
          }
        }
      }

      &.exit-active {
        > ._menu-title-area {
          > ._title {
            transform: translateX(-100%);
            transition: all $fDur $transition;
          }
        }
      }

      &.exit-done {
        > ._menu-title-area {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    // Backwards animations (shared)
    &.-backwards > ._menu-body {
      &.enter {
        > ._menu-items-list {
          transform: translateX(-100%);
        }
      }

      &.enter-active {
        > ._menu-items-list {
          transform: translateX(0);
          transition: all $fDur $transition;
        }
      }

      &.exit {
        > ._menu-items-list {
          transform: translateX(0);
        }
      }

      &.exit-active {
        > ._menu-items-list {
          transform: translateX(100%);
          transition: all $fDur $transition;
        }
      }
    }

    // Backwards animations (next -> root)
    &.-backwards.-next-root > ._menu-body {
      &.enter {
        > ._default-title {
          opacity: 0;
        }
      }

      &.enter-active {
        > ._default-title {
          opacity: 1;
          transition: all $hDur $transition $hDur;
        }
      }

      &.exit {
        > ._menu-title-area {
          height: $activeTitleHeight;
          opacity: 1;

          > * {
            opacity: 1;
          }
        }
      }

      &.exit-active {
        > ._menu-title-area {
          height: $defaultTitleHeight;
          opacity: 0;
          transition: all $fDur $transition;

          > * {
            opacity: 0;
            transition: all $qDur $transition;
          }
        }
      }
    }

    // Backwards animations (next -> deep)
    &.-backwards.-next-deep > ._menu-body {
      &.enter {
        > ._menu-title-area {
          opacity: 0;

          > ._title {
            transform: translateX(-100%);
          }
        }
      }

      &.enter-active {
        > ._menu-title-area {
          opacity: 1;
          transition: all $fDur $transition;

          > ._title {
            transform: translateX(0);
            transition: all $fDur $transition;
          }
        }
      }

      &.exit {
        > ._menu-title-area {
          > ._title {
            transform: translateX(0);
          }
        }
      }

      &.exit-active {
        > ._menu-title-area {
          > ._title {
            transform: translateX(100%);
            transition: all $fDur $transition;
          }
        }
      }
    }
  }
}
