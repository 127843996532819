.category-page-menu > .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;

  > .dropdown-header {
    display: flex;
    align-items: center;

    ._chevron {
      margin-left: auto;
      transform: rotate(90deg);
      transition: transform 0.5s;
    }

    ._name {
      @include typography-desktop-text-small;

      @include bp($mobileBreakpoint) {
        font-size: 16px;
      }
    }
  }

  &.-open {
    > .dropdown-header {
      ._chevron {
        transform: rotate(-90deg);
      }
    }
  }

  > .dropdown-body > ._wrapper {
    > ._menu-item {
      display: inline-block;

      &.-parent {
        @include typography-desktop-text-small;
        margin-bottom: 15px;
      }

      &.-current-depth {
        @include inter(600);
        font-size: 16px;
        line-height: 24px;
        color: $primaryPink;
        margin-bottom: 4px;
      }

      &.-parent-depth {
        @include inter(400);
        font-size: 16px;
        line-height: 24px;
        color: $primaryBlack;
        margin-bottom: 4px;
        position: relative;
        @include hover-underline;
        width: fit-content;
      }
    }

    > ._menu-items {
      display: flex;
      flex-direction: column;

      > ._menu-item {
        @include inter(400);
        font-size: 16px;
        line-height: 18px;
        @include hover-underline;
        margin: 7px 0;
        overflow: hidden;
        white-space: normal;
        margin-right: auto;

        &.-active {
          @include inter(600);
          color: $primaryPink;
        }

        &.-indent {
          margin-left: 16px;
        }
      }

      > ._show-more-btn {
        border: none;
        cursor: pointer;
        background: none;
        @include hover-underline;
        display: inline-block;
        padding: 0;
        margin: 0;
        @include inter(400);
        color: $primaryPink;
        text-align: left;
        width: fit-content;
        margin-top: 4px;

        > svg {
          margin-left: 4px;
          margin-bottom: -1px;
          height: 14px;
        }
      }
    }
  }
}
