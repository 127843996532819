.toggle-input {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;

  > ._label {
    @include typography-mobile-text-x-small;
    color: $grey5;
    user-select: none;
  }

  > ._toggle {
    height: 24px;
    width: 44px;
    background: $grey1;
    position: relative;
    padding: 4px 6px;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    outline: 1px solid rgba($primaryDarkBlue, 0.1);

    > ._bubble {
      height: 16px;
      width: 16px;
      position: absolute;
      background: #fff;
      border-radius: 100%;
      box-shadow:
        0px 16px 24px rgba(8, 5, 27, 0.06),
        0px 2px 6px rgba(8, 5, 27, 0.04),
        0px 0px 1px rgba(8, 5, 27, 0.04);
      left: 6px;
      transition: all 0.2s ease-in-out;
    }

    &.-active {
      background: $primaryPink;

      > ._bubble {
        left: 22px;
      }
    }
  }
}
