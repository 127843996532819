button.button {
  @include inter(600);
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  padding: 0 14px;
  position: relative;
  overflow: hidden;

  > ._children {
    // Icon support
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    padding-bottom: 4px;

    // _loader broke this rule, so put all children in separate div
    > svg:not(:only-child) {
      height: 16px;
      margin-right: 8px;
    }
  }

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  &.-rounded {
    border-radius: 100%;
    border: 1px solid rgba($primaryDarkBlue, 0.1);
  }

  &.-primary {
    background: $primaryPink;
    color: $grey1;

    &:hover,
    &:focus {
      background: $primaryPinkHover;
    }

    &:active {
      background: #d90787;
    }

    &:disabled {
      background: #f5a5d6;
      color: #fbdbef;

      > ._children {
        > svg,
        > svg path {
          fill: #fbdbef;
        }
      }
    }
  }

  &.-secondary {
    background: $grey1;
    color: $primaryBlack;

    &:hover,
    &:focus {
      background: $grey1;
      color: $primaryPink;

      // > ._children {
      //   > svg,
      //   > svg path {
      //     stroke: $primaryPink;
      //     fill: $primaryPink;
      //   }
      // }
    }

    &:active {
      background: $grey2;
    }

    &:disabled {
      background: #eff0f6;
      color: #93929e;

      > ._children {
        opacity: 0.5;
      }
    }
  }

  &.-danger {
    background: $darkRed;
    color: #fff;

    &:hover,
    &:focus {
      background: #ff3838;
    }
    &:focus {
      box-shadow: inset 0 0 0 3px #ff9e9e;
    }

    &:active {
      background: $darkRed;
    }

    &:disabled {
      background: #ff7c7c;
      color: #ffd1d1;

      > ._children {
        > svg,
        > svg path {
          fill: #ffd1d1;
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.-loading > ._loader {
    opacity: 1;
  }

  // Loader colors
  &.-primary {
    > ._loader {
      background: rgba($primaryPink, 0.2);

      &:before,
      &:after {
        background-color: $primaryPink;
      }
    }
  }

  &.-secondary {
    > ._loader {
      background: rgba($grey4, 0.2);

      &:before,
      &:after {
        background-color: $grey4;
      }
    }
  }

  // Loader
  > ._loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    transition: all 0.2s $transition;
    opacity: 0;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
    }

    &:after {
      content: '';
      position: absolute;
      background-color: inherit;
      top: 0;
      left: 0;
      bottom: 0;
      will-change: left, right;
      animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
    }

    @keyframes indeterminate {
      0% {
        left: -35%;
        right: 100%;
      }
      60% {
        left: 100%;
        right: -90%;
      }
      100% {
        left: 100%;
        right: -90%;
      }
    }

    @keyframes indeterminate-short {
      0% {
        left: -200%;
        right: 100%;
      }
      60% {
        left: 107%;
        right: -8%;
      }
      100% {
        left: 107%;
        right: -8%;
      }
    }
  }
}
