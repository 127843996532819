div.account-navigation-menu-items {
  padding: 24px;
  background-color: #fff;
  min-width: 320px;
  max-width: 32px;
  border-radius: 8px;

  .menu-item {
    display: flex;
    align-items: center;
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    .icon {
      margin-right: 8px;
      height: 24px;
      width: 24px;
    }

    &:hover,
    &.-active {
      color: $primaryPink;

      .icon {
        // Apply $primaryPink color filter
        filter: $primaryPinkFilter;
      }
    }

    &.-active {
      &::before {
        content: ' ';
        position: absolute;
        left: -24px;
        top: 0;
        bottom: 0;
        width: 4px;
        background: $primaryPink;
        border-radius: 0px 100px 100px 0px;
      }
    }
  }
}
