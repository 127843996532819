.page.-basket-page {
  ._loader-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ._empty-wrapper {
    padding: 24px 0;

    > ._text {
      @include typography-desktop-text-x-small($grey3);
      margin-bottom: 14px;
    }
  }

  ._content-wrapper {
    display: flex;
    row-gap: 24px;
    column-gap: 48px;

    @include bp(1000px) {
      flex-direction: column;
    }

    > ._left-content {
      width: 100%;

      @include bp(1200px) {
        column-gap: 36px;
      }
    }

    > ._right-content {
      max-width: 350px;
      width: 100%;
      position: sticky;
      height: fit-content;
      top: 30px;

      @include bp(1000px) {
        max-width: 100%;
      }

      > .side-box-section {
        position: relative;
        top: 0px;
      }

      > ._side-links-section {
        background: $grey1;
        border-radius: 8px;
        padding: 24px;
        margin-top: 32px;
        z-index: 2;

        > ._subtitle {
          @include typography-desktop-text-large;
          margin-bottom: 8px;
        }

        > ._links {
          padding: 0;
          margin: 0;
          list-style: none;

          > li {
            &:not(:last-child) {
              margin-bottom: 8px;
            }

            > a {
              @include typography-desktop-text-x-small($grey5);
              @include hover-underline;

              color: $primaryPink;
            }
          }
        }
      }
    }
  }
}
