.product-main-attributes {
  list-style: none;
  padding: 0;
  margin: 0;

  > li {
    font-size: 14px;
    line-height: 17px;
    color: $grey5;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    > ._value {
      font-weight: 700;
    }

    @include bp($mobileBreakpoint) {
      @include typography-mobile-text-x-small($grey5);
    }
  }
}
