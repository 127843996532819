@mixin typography-desktop-link-x-small($color: $primaryDarkBlue, $hover: $grey3) {
  @include inter(600);
  font-size: 14px;
  line-height: 24px;
  color: $color;
  cursor: pointer;

  &:hover {
    color: $hover;
  }
}

@mixin typography-mobile-text-x-small($color: $primaryDarkBlue) {
  @include inter(500);
  font-size: 14px;
  line-height: 22px;
  color: $color;
}

@mixin typography-desktop-text-x-small($color: $primaryDarkBlue) {
  @include inter(500);
  font-size: 16px;
  line-height: 24px;
  color: $color;

  @include bp($mobileBreakpoint) {
    @include typography-mobile-text-x-small($color);
  }
}

@mixin typography-mobile-text-large($color: $primaryDarkBlue) {
  @include inter(600);
  font-size: 20px;
  line-height: 32px;
  color: $color;
}

@mixin typography-desktop-text-large($color: $primaryDarkBlue) {
  @include inter(600);
  font-size: 24px;
  line-height: 32px;
  color: $color;

  @include bp($mobileBreakpoint) {
    @include typography-mobile-text-large($color);
  }
}

@mixin typography-mobile-text-small($color: $primaryDarkBlue) {
  @include inter(600);
  font-size: 14px;
  line-height: 18px;
  color: $color;
}

@mixin typography-desktop-text-small($color: $primaryDarkBlue) {
  @include inter(700);
  font-size: 16px;
  line-height: 24px;
  color: $color;

  @include bp($mobileBreakpoint) {
    @include typography-mobile-text-small($color);
  }
}

@mixin typography-label-caption($color: $grey4) {
  @include inter(500);
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: $color;
}

@mixin typography-desktop-text-medium($color: $primaryDarkBlue) {
  @include inter(600);
  font-size: 18px;
  line-height: 26px;
  color: $color;
}

@mixin typography-mobile-display-medium-bold($color: $primaryBlack) {
  @include inter(700);
  font-size: 32px;
  line-height: 36px;
  letter-spacing: 0px;
  color: $color;
}

@mixin typography-desktop-display-medium-bold($color: $primaryBlack) {
  @include inter(700);
  font-size: 56px;
  line-height: 50px;
  letter-spacing: 0px;
  color: $color;

  @include bp($mobileBreakpoint) {
    @include typography-mobile-display-medium-bold($color);
  }
}

@mixin typography-mobile-display-small-medium($color: $primaryBlack) {
  @include inter(600);
  font-size: 24px;
  line-height: 32px;
  color: $color;
}

@mixin typography-desktop-display-small-medium($color: $primaryBlack) {
  @include inter(600);
  font-size: 32px;
  line-height: 34px;
  letter-spacing: 0px;
  color: $color;

  @include bp($mobileBreakpoint) {
    @include typography-mobile-display-small-medium($color);
  }
}

@mixin typography-error-message-desktop {
  @include inter(400);
  color: $primaryPink;
  margin-top: 4px;
  font-size: 12px;
  line-height: 14px;
}
