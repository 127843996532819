@import '~leaflet';

/* .at-filter {
  // Firefox disables filter if display:none
  visibility: hidden;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
} */

.leaflet-container {
  .leaflet-control-zoom {
    a:hover {
      text-decoration: none;
    }
  }
  .leaflet-tile {
    // filter: url('#at-filter');
    filter: saturate(0.4);
  }

  .leaflet-pane.leaflet-shadow-pane {
    mix-blend-mode: multiply;
  }

  a {
    color: $primaryDarkBlue;
    &:hover {
      color: $primaryPink;
      text-decoration: underline;
    }
  }

  .leaflet-popup {
    .popup-shop-name {
      color: $primaryDarkBlue;
    }

    .popup-important-text {
      color: $primaryPink;
    }
    &.hat {
      padding-top: 20px;
      .leaflet-popup-close-button {
        padding-top: 20px;
      }
      &::after {
        content: '';
        position: absolute;
        pointer-events: none;
        width: 40px;
        height: 40px;
        top: 3px;
        right: -15px;
        background-image: url('../../images/hat.png');
        background-size: contain;
        filter: drop-shadow(1px 1px 1px);
      }
    }
  }
}

.leaflet-container.leaflet-dark {
  .leaflet-control-zoom {
    filter: invert(1);
    a {
      color: $grey3;
    }
  }
  .leaflet-tile {
    filter: invert(1) hue-rotate(180deg) saturate(0.05);
    //filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%) saturate(0.2);
  }
  .leaflet-control-attribution {
    background: linear-gradient(to left, #760654 0%, #08051b 100%);
    color: $grey1;
  }

  a:not(.leaflet-popup a) {
    color: $grey2;
  }

  .leaflet-pane.leaflet-shadow-pane {
    mix-blend-mode: multiply;
  }

  /* .leaflet-popup {
    .popup-shop-name {
      color: $grey1;
    }
    .popup-text {
      color: $grey2;
    }
    .popup-important-text {
      color: $primaryPink;
    }
    .leaflet-popup-content-wrapper {
      border: 1px solid rgba(255, 255, 255, 0.5);
      background: radial-gradient(100% 100% at 100% 0%, #760654 0%, #08051b 100%);
    }
    .leaflet-popup-tip {
      background-color: #08051b;
      border: 1px solid rgba(255, 255, 255, 0.5);
    }
    .leaflet-popup-close-button {
      color: $grey3;
      &:hover {
        color: $grey2;
      }
      &:active {
        color: $grey5;
      }
    }
  } */
}
