div.tooltip {
  z-index: 1000000;
  pointer-events: none;
  position: absolute;
  padding: 6.5px 16px;
  border-radius: 8px;
  white-space: nowrap;
  background: $grey5;
  max-width: 220px;
  box-shadow: none;
  border: none;

  @include typography-desktop-text-x-small($grey1);
  white-space: pre-line;

  &::before {
    content: '';
    position: absolute;
    top: -6px;
    left: 21px;
    right: 0;
    width: 6px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid $grey5;
  }

  &.-arrow-left {
    &::before {
      left: 21px;
      right: initial;
    }
  }

  &.-arrow-right {
    &::before {
      right: 21px;
      left: initial;
    }
  }

  &.-arrow-up {
    &::before {
      top: -6px;
      bottom: initial;
    }
  }

  &.-arrow-down {
    &::before {
      bottom: -6px;
      top: initial;
      transform: scaleY(-1);
    }
  }
}
