/* Wysiwyg styles */
.wysiwyg,
.wysiwyg * {
  max-width: 100% !important;
  word-break: break-word;
  overflow-wrap: break-word;
}

.wysiwyg {
  overflow: hidden;
}

.wysiwyg img {
  object-fit: contain;
  border-radius: 6px;
}

.wysiwyg a:hover {
  color: #e3068c;
}

.wysiwyg ul {
  list-style: square;
  padding-left: 20px;
}

.wysiwyg li {
  margin-bottom: 8px;
}

.wysiwyg li::marker {
  color: #e3068c;
}

.wysiwyg strong {
  font-weight: 600;
}

.wysiwyg ol {
  list-style: decimal;
  padding-left: revert; /* fix 10th number cut off */
}

.wysiwyg pre {
  white-space: break-spaces;
}

.wysiwyg blockquote {
  background: #f7f7fc;
  border-left: 3px solid #ccc;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h3,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6 {
  margin: 2rem 0 1rem 0;
  font-weight: 700;
}

.wysiwyg h1 {
  font-size: 56px;
  line-height: 64px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.wysiwyg h2 {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 40px;
  margin-top: 40px;
}

.wysiwyg h3 {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.wysiwyg h4 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 24px;
  margin-top: 24px;
}

.wysiwyg h1:first-child,
.wysiwyg h2:first-child,
.wysiwyg h3:first-child,
.wysiwyg h4:first-child,
.wysiwyg h5:first-child,
.wysiwyg h6:first-child {
  margin-top: 0;
}

.wysiwyg p,
.wysiwyg div,
.wysiwyg span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.wysiwyg.larger,
.wysiwyg.larger p,
.wysiwyg.larger div,
.wysiwyg.larger span {
  font-size: 20px;
}

.wysiwyg:not(.tiny):not(:empty) p,
.wysiwyg:not(.tiny):not(:empty) div,
.wysiwyg:not(.tiny):not(:empty) span {
  margin: 1rem 0;
}

.wysiwyg.tiny p,
.wysiwyg.tiny div,
.wysiwyg.tiny span {
  margin: 2px 0;
}

.wysiwyg a {
  color: #e3068c;
}

.wysiwyg a:hover {
  text-decoration: underline;
}

.wysiwyg p:first-child {
  margin-top: 0 !important;
}

.wysiwyg p:last-child {
  margin-bottom: 0 !important;
}

.wysiwyg-dark,
.wysiwyg-dark p,
.wysiwyg-dark div,
.wysiwyg-dark span,
.wysiwyg-dark li,
.wysiwyg-dark h1,
.wysiwyg-dark h2,
.wysiwyg-dark h3,
.wysiwyg-dark h4,
.wysiwyg-dark h5,
.wysiwyg-dark h6 {
  color: #08051b;
}

.wysiwyg-dark a {
  color: #e3068c;
}

.wysiwyg-dark a:hover {
  text-decoration: underline;
}

.wysiwyg table.simple-attrs-in-desc {
  margin-top: 40px;
  width: 100%;
}

.wysiwyg table.simple-attrs-in-desc tr {
  padding: 10px 8px;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.wysiwyg table.simple-attrs-in-desc tr:nth-child(odd) {
  background: #f7f7fc;
}

.wysiwyg table.simple-attrs-in-desc tr:last-child {
  padding-bottom: none;
  border-bottom: none;
}

.wysiwyg table.simple-attrs-in-desc th {
  min-width: 80px;
  align-self: center;
  text-align: left;

  font-size: 0.75rem /* 12px */;
  line-height: 0.875rem /* 14px */;
  letter-spacing: 0.04em;
  font-weight: 500;

  text-transform: uppercase;
  color: #4e4b66;
}

.wysiwyg table.simple-attrs-in-desc td {
  text-align: right;

  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;

  font-weight: 500;
}
