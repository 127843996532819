.react-day-picker-wrapper {
  position: relative;
  display: inline-flex;
}

.react-day-picker {
  position: absolute;
  z-index: 100;
  transition: all 300ms $transition;
  opacity: 0;
  transform: translateY(-50px);
  color: $primaryDarkBlue;

  &.enter,
  &.exit-done,
  &.exit-active {
    opacity: 0;
    transform: translateY(-50px);
  }

  &.enter-done {
    opacity: 1;
    transform: translateY(0px);
  }
}

.DayPicker {
  margin-top: 12px;
  border-radius: 8px;
  @include box-shadow-full;
  overflow: hidden;

  .DayPicker-wrapper {
    background-color: #fff;
  }

  .DayPicker-Footer {
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .DayPicker-TodayButton {
    @include typography-desktop-text-x-small($primaryPink);
  }

  .DayPicker-Month {
    border-collapse: separate;
    border-spacing: 4px;
  }

  .DayPicker-Day {
    border-radius: 8px;
  }

  .DayPicker-Caption {
    @include typography-desktop-text-small($primaryBlack);
  }

  .DayPicker-Weekday {
    display: table-cell;
    @include typography-mobile-text-x-small($grey3);
  }

  .DayPicker-Day--today {
    color: inherit;
    font-weight: 700;
  }

  .DayPicker-Day--disabled {
    color: $grey1;
    cursor: default;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;

    background-color: $primaryPink;
    color: #fff;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    opacity: 0.8;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #fff;
  }
}

.date-range-input {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  @include typography-desktop-text-x-small($primaryPink);
  min-width: 220px;

  display: flex;
  align-items: center;

  svg {
    margin-left: 4px;
  }
}
