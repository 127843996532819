.checkbox-input {
  &:not(.-disabled):hover {
    cursor: pointer;

    > label._checkbox-wrapper {
      input:not(:checked) ~ .check {
        background-color: $grey3;
      }
    }
  }

  label._checkbox-wrapper {
    cursor: pointer;
    display: block;
    user-select: none;
    position: relative;
    padding-left: 30px;
    margin: 8px 0;
    @include typography-desktop-text-x-small($grey5);

    @include bp($mobileBreakpoint) {
      font-size: 16px;
    }

    a {
      color: $primaryPink;
      @include hover-underline;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      width: 0;
      height: 0;

      &:checked ~ .check {
        background-color: $primaryPink;

        &:after {
          display: block;
        }
      }
    }

    .check {
      position: absolute;
      top: 2px;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: $grey2;
      border-radius: 4px;

      &:after {
        content: '';
        position: absolute;
        left: 7px;
        top: 4px;
        width: 6px;
        height: 9px;
        display: none;

        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  &.-error {
    > label._checkbox-wrapper {
      margin-bottom: 0px;
      color: $primaryPink;
    }

    > ._error {
      display: inline-block;
      margin-bottom: 8px;
    }
  }

  &.-required {
    > label::after {
      content: '*';
    }
  }

  > ._error {
    @include typography-error-message-desktop;
  }

  &.-disabled {
    opacity: 0.6;

    &,
    label {
      cursor: not-allowed;
    }
  }

  &.-light label._checkbox-wrapper {
    color: #fff;
  }
}
