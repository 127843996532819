table.box-table {
  width: 100%;
  padding-bottom: 12px;
  overflow-x: auto;

  thead th {
    @include typography-label-caption();
    padding-bottom: 8px;
    border-bottom: 1px solid $grey2;

    &:not(:first-child) {
      padding-left: 12px;
    }
  }

  tbody tr td {
    padding-top: 16px;
    @include typography-desktop-text-x-small($primaryDarkBlue);
  }
}
