.forgot-password-form {
  width: 325px;
  margin: 0 auto;
  .form-title {
    margin-bottom: 28px;
    text-align: center;
    @include typography-desktop-text-large($primaryBlack);
  }

  .submit-btn {
    margin-top: 16px;
    width: 100%;
    text-align: center;
  }

  .return-links {
    display: flex;
    flex-direction: column;
    align-items: center;

    ._text {
      margin-top: 16px;
      cursor: pointer;
      @include typography-desktop-text-x-small($primaryPink);
      @include hover-underline;
    }
  }
}
