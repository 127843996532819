.catalogue-product-wrapper.-grid .catalogue-product-price {
  margin: 0;

  &.-outside {
    display: none;
  }

  ._main-price {
    &.-sale {
      margin-right: 16px;
    }
  }
}
