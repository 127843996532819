.side-box-section-info-box.-availability {
  > ._content {
    &.-orderable {
      display: flex;
      flex-direction: column;

      > ._title {
        @include inter(600);
        font-size: 18px;
        letter-spacing: 0.02em;
        color: $grey5;
      }

      > ._description {
        margin-top: 22px;
        @include inter(400);
        font-size: 16px;
        line-height: 24px;
        color: $grey5;
      }
    }

    > ._availability-list {
      > ._city-list-item {
        display: flex;
        align-items: center;
        margin-bottom: 2px;

        > ._icon {
          height: 10px;
          width: 10px;
          position: relative;

          > svg {
            position: absolute;
            height: 100%;
            width: 100%;
          }
        }

        > ._name {
          @include inter(400);
          font-size: 16px;
          line-height: 24px;
          color: $grey5;
          margin-left: 8px;
        }
      }
    }

    > ._show-btn-wrapper {
      margin-top: 12px;

      > ._show-specific-btn {
        @include inter(400);
        font-size: 16px;
        line-height: 24px;
        color: $primaryPink;
        @include hover-underline;
      }
    }
  }
}
