.dur-200 {
  &.enter-active,
  &.exit-active {
    transition: all 200ms $transition;
  }
}

.dur-300 {
  &.enter-active,
  &.exit-active {
    transition: all 300ms $transition;
  }
}

.dur-500 {
  &.enter-active,
  &.exit-active {
    transition: all 500ms $transition;
  }
}

.css-anim-fade {
  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
  }
}
