.campaign-page-modular-products-section {

  > ._title {
    @include typography-desktop-display-small-medium;
    margin-bottom: 27px;
    color: $primaryBlack;
  }

  > ._products-list {
    position: relative;
    min-height: 160px;
    width: 100%;

    // .catalogue-product-wrapper {
    //   &:hover {
    //     .catalogue-product {
    //       ._body {
    //         ._name {
    //           color: $primaryPink;
    //         }
    //       }
    //     }
    //   }

    //   &::after {
    //     display: none;
    //   }

    //   .catalogue-product {
    //     ._body {
    //       li {
    //         color: $grey4;
    //       }
    //     }
    //   }
    // }

    > .loader {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }

  // Set text colors, image background
  // ._campaign-page-wrapper.-text-light & {
  //   > ._title {
  //     color: #fff;
  //   }

  //   > ._products-list > .catalogue-product-wrapper {
  //     // &:hover .catalogue-product > ._image-wrapper {
  //     //   filter: none;
  //     // }

  //     > .product-labels {
  //       left: 2px;
  //       top: 2px;
  //     }

  //     .catalogue-product {
  //       > ._image-wrapper {
  //         filter: brightness(85%);

  //         > img {
  //           background: #fff;
  //           border-radius: 8px;
  //         }
  //         > .product-stock-label {
  //           bottom: 2px;
  //           left: 2px;
  //         }
  //       }

  //       ._name {
  //         color: $grey2;
  //       }
  //       .catalogue-product-price > ._main-price:not(.-sale) {
  //         background: $grey5;
  //       }
  //       .product-main-attributes > li {
  //         color: $grey2;
  //       }
  //     }
  //   }
  // }

  ._campaign-page-wrapper.-text-dark & {
    > ._title {
      color: $primaryBlack;
    }

    > ._products-list > .catalogue-product ._name {
      color: $primaryBlack;
    }
  }
}
