.slider-input {
  > ._header {
    display: flex;
    margin-bottom: 7px;

    > ._title {
      @include typography-label-caption;
      margin-top: auto;
      margin-bottom: 2px;
    }

    > ._right-value {
      padding: 8px 12px;
      background: $grey1;
      border-radius: 32px;
      max-width: 100px;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin-left: auto;

      input {
        width: 100%;
        background: none;
        border: none;
        outline: none;
      }

      > span,
      input {
        @include inter(600);
        color: $primaryDarkBlue;
        font-size: 14px;
        text-align: right;
        line-height: 14px;
        margin: 0;
        padding: 0;
      }

      > span {
        margin-left: 4px;
        margin-bottom: -2px;
      }
    }
  }

  .slider {
    position: relative;
    height: 16px;
    user-select: none;

    > ._rail {
      height: 8px;
      border-radius: 40px;
      background: $grey2;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    > ._handles {
      > ._handle {
        position: absolute;
        top: -1px;
        height: 18px;
        width: 18px;
        outline: 2px solid #fff;
        background: $primaryPink;
        border-radius: 50%;
        margin-left: -8px;
        z-index: 5;
        cursor: pointer;
        touch-action: pan-x;

        &:hover {
          opacity: 0.9;
          transform: scale(1.1);
        }
      }
    }

    > ._tracks {
      > ._track {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;

        height: 10px;
        z-index: 1;
        border-radius: 10px;
        background: $primaryPink;
        border: 1px solid #edb71e;
      }
    }
  }
}
