div.login-box {
  > ._title {
    @include typography-desktop-text-large($primaryDarkBlue);
    margin-bottom: 6px;
  }

  > ._description {
    @include typography-desktop-text-x-small($grey5);
  }

  > ._btn-wrapper {
    margin-top: 24px;

    button.button {
      display: inline-block;
    }
  }
}
