div.modal > .confirmation-modal {
  min-height: 200px;
  max-width: 600px;
  display: flex;
  flex-direction: column;

  > ._title {
    @include typography-desktop-text-large;
    padding: 28px 40px 20px 40px;

    @include bp($mobileBreakpoint) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  > ._content-text {
    @include typography-desktop-text-x-small;
    padding: 0 40px 40px 40px;
    flex: 1;
    overflow-y: auto;

    @include bp($mobileBreakpoint) {
      padding-left: 16px;
      padding-right: 16px;
    }

    b {
      font-weight: 700;
    }

    a {
      color: $primaryPink;
      @include hover-underline;
    }

    &,
    > p,
    > div,
    > span {
      @include typography-desktop-text-x-small($grey5);
      font-weight: 400;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 1rem 0;
    }

    > * {
      max-width: 100% !important;
    }
  }

  > ._options {
    display: flex;
    margin-top: auto;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid $grey2;
    padding: 16px 24px;
    flex-shrink: 0;

    @include bp($mobileBreakpoint) {
      padding-left: 16px;
      padding-right: 16px;
    }

    > ._option-btn {
      min-width: 100px;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
