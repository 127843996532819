*,
*::before,
*::after {
  box-sizing: inherit;
}

input:focus {
  outline-width: 0;
}

html {
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 320px;
  box-sizing: border-box;
}

body {
  @include inter();
  -webkit-overflow-scrolling: touch;
  color: white;
  background: $primaryBlack;
  min-width: 360px;

  *::selection {
    background: $grey3;
  }

  *:focus {
    outline: none;
  }

  *:focus-visible,
  *.focus-visible {
    outline: 1px dashed $primaryPink;
  }

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.contain-paint {
  // used in place of overflow:hidden to force round corners and fix sticky elements
  // NB! select options other body elements will not render above footer
  contain: paint;
}

table,
tr,
td {
  border: none;
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include inter();
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  padding: 0;
  margin: 0;
  line-height: 1.3;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: $primaryPink;
  }
}

#react-app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
