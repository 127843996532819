.price-input-field {
  display: flex;
  align-items: center;

  ._separator {
    @include typography-desktop-text-x-small($grey3);
    margin: 0 8px;
  }

  div.price-input-wrapper {
    background-color: $grey1;
    border-radius: 4px;
    padding: 8px;
    position: relative;

    input.input {
      border: none;
      outline: none;
      height: 24px;
      max-width: 64px;
      width: 64px;
      background-color: $grey1;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin-left: 12px;
      }
    }
    &::after {
      content: '\20AC';
      position: absolute;
      top: 50%;
      right: 6px;
      transform: translateY(-50%);
    }
  }

  .clear-price-input-button {
    margin-left: auto;
    border: transparent;
    background-color: transparent;
    outline: none;
    cursor: pointer;
  }
}
