.active-filters {
  margin-top: 1.5rem;

  > ._wrapper {
    display: flex;
    flex-wrap: wrap;
    transition: all 0.2s $transition;

    &:empty {
      max-height: 0;
    }

    &:not(:empty) {
      margin: 0 0 35px 0;
    }
  }

  ._active-filter,
  ._reset-button {
    margin: 2px;
    padding: 4px 16px;
    border-radius: 32px;
    background-color: $grey1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.1s $transition;
    margin-bottom: 16px;

    &:first-child {
      margin-left: 0px;
    }

    ._label {
      @include typography-desktop-text-x-small;
      margin-right: 12px;
    }

    ._close-btn {
      border: transparent;
      outline: none;
      background: transparent;
      margin: 1px 0 0 0;
      padding: 0;
      cursor: pointer;
    }

    svg > path {
      transition: fill 0.1s $transition;
    }
  }

  ._active-filter:hover {
    border-color: $primaryPink;

    svg > path {
      stroke: $primaryPink;
    }
  }

  ._reset-button {
    border: none;
    background: none;
    cursor: pointer;

    ._label {
      @include inter(700);
      margin-right: 6px;
    }

    > ._close-btn {
      margin: 0;
    }

    &:hover {
      > span {
        color: $primaryPink;
      }

      svg path {
        fill: $primaryPink;
      }
    }
  }
}
