.user-login-tabs {
  flex: 1;
  position: relative;
  width: 100%;

  .tabs {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    width: 100%;
    max-height: 100%;

    @include minbp($mobileBreakpoint) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border: none;
      box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  .tab-content,
  .tab-buttons {
    margin: 0 auto;
    width: 100%;
    max-width: 360px;
    position: relative;

    @include bp($mobileLBreakpoint) {
      max-width: 360px;
    }
  }

  .tab-buttons {
    display: flex;
    margin: 8px auto 32px auto;
    height: 44px;
    border-radius: 4px;
    overflow: hidden;
    border-bottom: 1px solid $grey2;

    .tab-button-wrapper {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:not(:last-child) {
        margin-right: 10px;
      }

      > .tab-button {
        @include inter(500);
        font-size: 16px;
        line-height: 24px;
        color: $grey5;
        height: 100%;
        width: 100%;
      }

      &:hover {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 4px;
          width: 100%;
          background-color: $primaryPink;
          border-radius: 100px 100px 0px 0px;
        }

        > .tab-button {
          color: $primaryPink;
        }
      }

      &.-active {
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          height: 4px;
          width: 100%;
          background-color: $primaryPink;
          border-radius: 100px 100px 0px 0px;
        }

        > .tab-button {
          font-weight: 700;
        }
      }
    }
  }
}
