.account-navigation-dropdown {
  $modalTransitionDuration: 200ms;
  position: absolute;
  z-index: 200;

  &::before {
    position: absolute;
    z-index: 13;
    content: ' ';
    top: -7px;
    right: 50%;
    height: 14px;
    width: 14px;

    background: #ffffff;
    transform: rotate(-45deg);

    @include bp($tabletBreakpoint) {
      right: unset;
      left: 84px;
    }
  }

  .dropdown-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba($primaryBlack, 0.6);
    z-index: 1;
    cursor: pointer;
  }

  .dropdown-content {
    background-color: #fff;
    position: relative;
    border-radius: 8px;
    z-index: 200;
    padding: 24px;
    width: 320px;
    overflow: hidden;
    transition: height 0.4s $transition;

    min-width: 320px;

    > ._loader-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(#fff, 0.8);
      z-index: 1;

      > .loader {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    ._role-selection-option {
      display: flex;
      align-items: center;
      padding: 10px 24px 10px 0;
      position: relative;

      ._info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        ._type {
          @include typography-label-caption($primaryBlack);
        }

        ._username {
          @include typography-desktop-text-x-small($primaryBlack);
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
          white-space: nowrap;
        }
      }

      > ._arrow {
        position: absolute;
        top: 0;
        bottom: 10px;
        margin: auto 0;
        right: 0;
      }

      &.-can-hover {
        cursor: pointer;

        &:hover {
          > ._info > * {
            color: $primaryPink;
          }

          > svg:not(._arrow) path {
            stroke: $primaryPink;
          }

          > ._arrow path {
            stroke: $primaryPink;
          }
        }
      }

      &.-active {
        > ._info > * {
          color: $primaryPink;
        }

        > svg:not(._arrow) path {
          stroke: $primaryPink;
        }

        > ._arrow path {
          stroke: $primaryPink;
        }

        &::before {
          content: '';
          width: 4px;
          height: 30px;
          position: absolute;
          left: -24px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          background: $primaryPink;
          border-radius: 0 100px 100px 0;
          overflow: hidden;
        }
      }
    }

    .account-navigation-menu-items {
      margin: 0 -24px;
    }

    > ._content.-select > ._title {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      cursor: pointer;

      > span {
        @include typography-desktop-text-medium;
        color: $primaryDarkBlue;
        padding: 0;
        margin: 0 0 0 20px;
      }

      &:not(.-clickable) > span {
        margin: 0;
      }

      &.-clickable:hover {
        > span {
          color: $primaryPink;
        }

        > svg > path {
          stroke: $primaryPink;
        }
      }
    }

    > ._content {
      transition: all 0.4s $transition;

      &.enter {
        &.-main {
          transform: translateX(-320px);
          opacity: 0;
        }

        &.-select {
          transform: translateX(320px);
          opacity: 0;
        }
      }

      &.enter-active {
        &.-main,
        &.-select {
          transform: translateX(0);
          opacity: 1;
        }
      }

      &.exit {
        &.-main,
        &.-select {
          top: 24px;
          position: absolute;
          transform: translateX(0);
          opacity: 1;
        }
      }

      &.exit-active {
        &.-main {
          transform: translateX(-320px);
          opacity: 0;
        }

        &.-select {
          transform: translateX(320px);
          opacity: 0;
        }
      }
    }
  }

  &.enter {
    > .dropdown-backdrop {
      opacity: 0;
    }

    > .dropdown-content {
      opacity: 0;
    }

    &::before {
      opacity: 0;
    }
  }

  &.enter-active {
    > .dropdown-backdrop {
      opacity: 1;
      transition: all $modalTransitionDuration $transition;
    }

    > .dropdown-content {
      opacity: 1;
      transition: all $modalTransitionDuration $transition;
    }

    &::before {
      opacity: 1;
      transition: all $modalTransitionDuration $transition;
    }
  }

  &.exit {
    > .dropdown-backdrop {
      opacity: 1;
    }

    > .dropdown-content {
      opacity: 1;
    }

    &::before {
      opacity: 1;
    }
  }

  &.exit-active {
    > .dropdown-backdrop {
      opacity: 0;
      transition: all $modalTransitionDuration $transition;
    }

    > .dropdown-content {
      opacity: 0;
      transition: all $modalTransitionDuration $transition;
    }

    &::before {
      opacity: 0;
    }
  }
}
