div.select-container {
  position: relative;
  display: inline-block;

  > .hidden-select {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-appearance: menulist-button;
    display: block;
    cursor: pointer;
  }

  > ._select {
    width: 100%;
    min-width: 80px;
    position: relative;
    padding: 8px 24px;
    background-color: $grey1;
    border-radius: 80px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 42px;
    border: 1px solid rgba($primaryDarkBlue, 0.1);

    ._chevron {
      height: 12px;
      width: 12px;
      transform: translateY(-50%) rotate(90deg);
      position: absolute;
      top: 50%;
      right: 20px;
    }

    ._value {
      @include typography-desktop-text-x-small($grey5);
      font-weight: 400;
    }
  }
}
