.main-nav-menu {
  backface-visibility: hidden;

  .main-nav-menu__background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 5001;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2px);
  }

  .main-nav-menu__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    width: 100%;
    max-width: 1920px;

    z-index: 5002;

    border-radius: 0 0 8px 8px;
    // overflow: hidden;

    .navigation-header {
      height: 80px;
      padding: 20px 24px;
      background-color: $primaryDarkBlue;

      display: flex;
      align-items: center;

      position: relative;
      z-index: 5;

      > ._logo-wrapper {
        height: 40px;
        width: 40px;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      > button {
        margin-left: 24px;
        background-color: transparent;
        border: none;
        cursor: pointer;

        display: flex;
        align-items: center;

        > svg {
          width: 22px;
        }

        span {
          margin-left: 8px;
          @include typography-desktop-text-medium($grey1);
        }
      }
    }

    .navigation-body {
      position: relative;
      // background-color: white;
      // height: 100%;
      // max-height: calc(100vh - 80px);
      // overflow: hidden;
    }

    .navigation-side {
      max-width: 340px;
      overflow-y: auto;

      max-height: 100%;
      height: 100%;
    }

    .navigation-menu__container {
      background-color: #fcfcfc;
      height: 100%;

      .navigation-menu__title {
        padding: 24px 20px 14px;
        @include typography-desktop-text-large($primaryDarkBlue);
      }

      .menu-item-hover-menu {
        // Remove navigation menu width
        width: calc(100% - 340px);
        background-color: $grey1;
      }

      > .navigation-menu {
        background-color: #fcfcfc;
        padding: 0;
        margin: 0;
        list-style: none;

        > .menu-item {
          color: $primaryBlack;
          padding: 16px 20px 16px;

          cursor: pointer;

          font-size: 16px;
          line-height: 24px;

          &:not(:last-child) {
            margin-bottom: 4px;
          }

          ._chevron {
            margin-left: auto;
            height: 14px;
          }

          > .menu-item__icon,
          > .menu-item__link-icon {
            svg,
            img {
              filter: $primaryBlackFilter;
            }

            .menu-item__text {
              display: flex;
              width: 100%;

              > svg {
                margin-left: auto;
              }
            }
          }

          &.-highlighted {
            color: $primaryPink;
            background-color: $grey1;

            > .menu-item__icon,
            > .menu-item__link-icon {
              svg,
              img {
                filter: $primaryPinkFilter;
              }
            }
          }
        }
      }
    }
  }

  // Animation
  &.enter {
    pointer-events: none;

    .main-nav-menu__content {
      .navigation-header {
        opacity: 0;

        > ._menu-btn {
          > svg {
            opacity: 0;
            transform: rotate(-360deg) scale(0);
          }
        }
      }

      .navigation-body {
        opacity: 0;
        transform: translateY(-100%) scaleY(0);
      }
    }

    .main-nav-menu__background {
      opacity: 0;
    }
  }

  &.enter-active {
    pointer-events: none;

    .main-nav-menu__content {
      .navigation-header {
        opacity: 1;
        transition: all 200ms $transition;

        > ._menu-btn {
          > svg {
            opacity: 1;
            transform: rotate(0) scale(1);
            transition: all 400ms $transition;
          }
        }
      }

      .navigation-body {
        opacity: 1;
        transform: translateY(0) scaleY(1);
        transition: all 300ms $transition 100ms;
      }
    }

    .main-nav-menu__background {
      opacity: 1;
      transition: all 400ms $transition;
    }
  }

  &.enter-done {
    pointer-events: initial;
    box-shadow: $box-shadow-full;
  }

  &.exit {
    .main-nav-menu__content {
      .navigation-header {
        opacity: 1;
      }

      .navigation-body {
        opacity: 1;
        transform: translateY(0) scaleY(1);
      }
    }

    .main-nav-menu__background {
      opacity: 1;
    }
  }

  &.exit-active {
    .main-nav-menu__content {
      .navigation-header {
        opacity: 0;
        transition: all 200ms $transition 200ms;
      }

      .navigation-body {
        opacity: 0;
        transform: translateY(-100%) scaleY(0);
        transition: all $mainMenuSlideDuration $transition;
      }
    }

    .main-nav-menu__background {
      opacity: 0;
      transition: all 400ms $transition;
    }
  }

  .slide-left-enter {
    transform: translateX(100%) skew($mainMenuSlideSkew);
    opacity: 0;
  }
  .slide-left-enter-active {
    transform: translateX(0%) skew(0);
    opacity: 1;
    transition:
      transform $mainMenuSlideDuration $mainMenuTransition,
      opacity $mainMenuSlideDuration $mainMenuTransition;
  }
  .slide-left-enter-done {
    transform: skew(0);
  }
  .slide-left-exit {
    transform: translateX(0%) skew(0);
    opacity: 1;
  }
  .slide-left-exit-active {
    transform: translateX(-100%) skew($mainMenuSlideSkew);
    opacity: 0;
    transition:
      transform $mainMenuSlideDuration $mainMenuTransition,
      opacity $mainMenuSlideDuration $mainMenuTransition;
  }

  /* Slide Right */
  .slide-right-enter {
    transform: translateX(-100%) skew(-$mainMenuSlideSkew);
    opacity: 0;
  }
  .slide-right-enter-done {
    transform: skew(0);
  }
  .slide-right-enter-active {
    transform: translateX(0%) skew(0);
    opacity: 1;
    transition:
      transform $mainMenuSlideDuration $mainMenuTransition,
      opacity $mainMenuSlideDuration $mainMenuTransition;
  }
  .slide-right-exit {
    transform: translateX(0%) skew(0);
    opacity: 1;
  }
  .slide-right-exit-active {
    transform: translateX(100%) skew(-$mainMenuSlideSkew);
    opacity: 0;
    transition:
      transform $mainMenuSlideDuration $mainMenuTransition,
      opacity $mainMenuSlideDuration $mainMenuTransition;
  }
}
