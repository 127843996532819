.account-header {
  margin: 32px 0 0 0;
  position: relative;
  display: flex;
  padding-bottom: 16px;
  padding-top: 32px;

  @include bp(900px) {
    flex-direction: column;
  }

  @include bp(640px) {
    padding: 0 16px;
  }

  > ._left-column {
    .active-user {
      @include typography-desktop-display-small-medium(#fff);
      margin-bottom: 8px;
    }

    > ._role-selection-wrapper {
      display: flex;

      > ._role-selection {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;

        > ._content {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          overflow: hidden;

          > ._name {
            @include typography-desktop-text-medium;
            max-width: 400px;
            color: $primaryPink;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 8px;
          }

          > ._role {
            display: inline-block;
            padding: 3px 6px;
            @include typography-label-caption;
            background: $grey4;
            color: $primaryBlack;
            border-radius: 8px;
            text-transform: uppercase;
          }
        }

        > ._arrow {
          margin-left: 12px;
        }
      }

      .account-navigation-dropdown {
        top: calc(100% + 20px);
        right: initial;

        &::before {
          left: 0;
          right: 0;
          margin: 0 auto;
        }
      }
    }
  }

  > ._right-action-buttons {
    margin: auto 0 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;

    @include bp(900px) {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}
