.campaign-box {
  min-height: 158px;
  height: 158px;
  background: $grey1;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-basis: calc(50% - 20px);
  transition: all 0.3s $transition;

  @include bp($mobileBreakpoint) {
    flex-direction: column-reverse;
    height: auto;
    padding: 22px 24px 30px 24px;
  }

  &:hover {
    @include box-shadow-md;
    transform: scale(1.01) translateZ(0);
  }

  > ._left {
    flex: 1;
    padding: 16px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;

    > ._title {
      @include typography-desktop-text-large($primaryDarkBlue);
    }

    > ._description {
      @include typography-desktop-text-x-small($primaryDarkBlue);
      @apply line-clamp-3;
    }

    @include bp($mobileBreakpoint) {
      padding: 0;
      text-align: center;
    }
  }

  > ._right-image {
    width: 211px;
    height: 100%;
    transform-origin: center center;

    @include bp($mobileBreakpoint) {
      width: 100%;
      margin-bottom: 12px;
      > img {
        border-radius: 4px;
        object-fit: contain;
      }
    }

    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}
