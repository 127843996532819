div.availability-filter {
  .dropdown-item {
    .dropdown-header {
      margin-top: 0;
    }
    .dropdown-body {
      margin-top: 0;
      padding-left: 16px;
      padding-top: 0;

      ._checkbox-wrapper {
        margin-top: 0;
        margin-bottom: 0;
      }

      ._wrapper {
        padding-bottom: 12px;
      }
    }

    .dropdown-header {
      display: flex;
      align-items: center;

      ._chevron {
        margin-left: auto;
        transform: rotate(90deg);
        transition: transform 0.5s;
      }

      ._checkbox-wrapper {
        margin-bottom: 0;
      }
    }

    &.-open {
      .dropdown-header {
        ._chevron {
          transform: rotate(-90deg);
        }
      }
    }
  }
}
