.campaign-page-modular-banner-section {
  width: 100%;

  &.-video {
    display: flex;
    align-items: center;
    justify-content: center;

    > iframe {
      width: 985px;
      height: 554px;

      @include bp($tabletBreakpoint) {
        width: 689px;
        height: 388px;
      }

      @include bp(600px) {
        width: 404px;
        height: 228px;
      }
    }
  }

  > img {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 8px;
  }
}
