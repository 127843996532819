.order-box-action-buttons {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex: 1;

  > * {
    margin: 4px 0 4px 8px;
  }
}
