.radio-input {
  &:not(.-disabled):hover {
    cursor: pointer;

    > label._radio-wrapper {
      input:not(:checked) ~ .check {
        background-color: $grey3;
      }
    }
  }

  > label._radio-wrapper {
    cursor: pointer;
    display: block;
    user-select: none;
    position: relative;
    padding-left: 30px; // 18px width + 12px padding
    @include typography-desktop-text-x-small($grey5);
    transition: all 0.2s $transition;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      &:checked ~ .check {
        background-color: $primaryPink;

        &:after {
          display: block;
        }
      }
    }

    .check {
      position: absolute;
      top: 3px;
      bottom: 3px;
      left: 0;
      margin: auto 0;
      height: 18px;
      width: 18px;
      background-color: $grey2;
      border-radius: 50%;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;

        display: none;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
      }
    }
  }

  &.-disabled {
    opacity: 0.6;

    &,
    label {
      cursor: not-allowed;
    }
  }
}
