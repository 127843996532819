.side-box-section-info-box {
  background: $grey1;

  &:not(:first-child) {
    margin-top: 24px;
  }

  > label {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    @include typography-label-caption($grey5);
    font-weight: 600;

    > svg {
      margin-right: 7px;
    }
  }
}
