.show-more-filters {
  @include typography-desktop-text-x-small($primaryPink);
  @include hover-underline;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  display: flex;
  align-items: center;

  svg {
    display: none;
    margin-left: 4px;

    &.up {
      transform: rotate(90deg);
    }
    &.down {
      transform: rotate(-90deg);
    }
  }
}
