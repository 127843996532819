.page.-customer-company-authorizations-page {
  .account-table {
    padding-bottom: 110px;
  }

  .account-table-row {
    position: relative;
  }

  ._email-cell-content {
    display: flex;
    align-items: center;

    > ._icon {
      display: flex;
      margin-right: 8px;
    }
  }

  ._privileges-select-cell {
    min-width: 200px;

    .searchable-select-wrapper .searchable-select {
      height: 40px;
      padding: 6px 8px;

      .searchable-select__select {
        top: 36px;
      }
    }
  }
}
