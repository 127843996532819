.modal > ._modal-content.new-authorization-modal {
  min-width: 386px;
  max-height: 90vh;

  > ._title {
    @include typography-desktop-text-large;
    margin: 32px 0;
    padding: 0 24px;

    @include bp($mobileBreakpoint) {
      padding: 0 16px;
    }
  }

  > ._close-btn {
    top: 34px;
  }

  > form {
    display: flex;
    flex-direction: column;

    > ._fields-wrapper {
      padding: 0 24px;

      @include bp($mobileBreakpoint) {
        padding: 0 16px;
      }

      > *:not(:first-child) {
        margin-top: 16px;
      }

      margin-bottom: 32px;
    }

    > ._action-buttons {
      display: flex;
      border-top: 1px solid $grey2;
      padding: 16px 24px;
      justify-content: flex-end;

      @include bp($mobileBreakpoint) {
        padding: 16px;
      }

      > *:first-child {
        margin-right: 16px;
      }
    }

    ._error {
      @include typography-error-message-desktop;
    }
  }
}

// Next level googats
._auth-modal-content {
  > a,
  > span {
    color: $primaryPink;
  }
}
