.create-new-case.button-wrapper {
  position: relative;
  display: inline-block;
}

.create-new-case.dropdown-wrapper {
  margin: 0;
  display: block;
  position: absolute;
  z-index: 100;
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  @include box-shadow-full;

  li {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    svg {
      margin-right: 16px;
    }
  }

  &::before {
    position: absolute;
    content: ' ';
    width: 16px;
    height: 16px;
    background: #ffffff;
    transform: rotate(-45deg);
    top: -8px;
    right: 21px;
  }

  &.-arrow-left {
    &::before {
      left: 21px;
      right: initial;
    }
  }

  &.-arrow-right {
    &::before {
      right: 21px;
      left: initial;
    }
  }

  &.-arrow-up {
    &::before {
      top: -6px;
      bottom: initial;
    }
  }

  &.-arrow-down {
    &::before {
      bottom: -6px;
      top: initial;
    }
  }

  &.enter {
    opacity: 0.8;
    transform: translateY(-12px);
  }

  &.enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 300ms $transition;
  }

  &.exit {
    opacity: 1;
    transform: translateY(0);
  }

  &.exit-active {
    opacity: 0;
    transform: translateY(-12px);
    transition: all 300ms $transition;
  }
}
