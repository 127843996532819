.quantity-selector {
  display: flex;
  user-select: none;

  > ._sub,
  > ._add {
    &:not(.-disabled) {
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }

    &.-disabled {
      opacity: 0.5;
    }
  }

  > ._input-wrapper {
    > input {
      min-width: 31px;
      background: $grey1;
      border: none;
      height: 32px;
      border-radius: 8px;
      overflow: hidden;
      margin: 0 3px;
      text-align: center;
      @include typography-desktop-text-x-small;
    }
  }
}
