@mixin hover-underline() {
  text-decoration: none;
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(currentColor, currentColor);
  background-size:
    100% 0.05em,
    0 0.05em;
  background-position:
    100% 100%,
    0 100%;
  background-repeat: no-repeat;
  transition:
    background-size 0.5s,
    background-position 0.5s;

  &:hover {
    background-size:
      0 0.05em,
      100% 0.05em;
    background-position:
      100% 100%,
      0 100%;
  }
}

.hover-underline {
  @include hover-underline;
}

.snowflake {
  pointer-events: none;
  --size: 1px;
  width: var(--size);
  height: var(--size);
  background: white;
  border-radius: 50%;
  position: absolute;
  top: -5vh;
  z-index: 60;
  filter: blur(2px);
  &:nth-child(2n) {
    filter: blur(1px);
  }
}

@keyframes snowfall {
  0% {
    transform: translate3d(var(--left-ini), 0, 0);
  }
  90% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
    transform: translate3d(var(--left-end), 100cqh, 0);
  }
}

@for $i from 1 through 75 {
  .snowflake:nth-child(#{$i}) {
    --size: #{random(5) * 1.2}px;
    --left-ini: #{random(20) - 10}vw;
    --left-end: #{random(20) - 10}vw;
    left: #{random(100)}vw;
    animation: snowfall #{5 + random(10)}s linear infinite;
    animation-delay: -#{random(10)}s;
  }
}

.heart-liked {
  animation: heart-expand-anim 0.5s 1;
}

@keyframes heart-expand-anim {
  0% {
    transform: scale(1);
  }
  90% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
