.breadcrumbs {
  @include typography-label-caption($grey3);
  display: flex;
  text-transform: uppercase;
  padding: 24px 0 34px 0;
  list-style: none;
  margin: 0;

  @include bp($mobileBreakpoint) {
    padding: 24px 0;
  }

  li {
    display: flex;

    ._link {
      display: inline-block;
      vertical-align: middle;
      max-width: 300px;
      color: $grey3;
      transition: all 0.2s $transition;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:not(:last-of-type) {
        min-width: 0;
        flex-shrink: 0;
      }

      &.-active {
        color: $primaryDarkBlue;
      }

      &:hover {
        color: $primaryDarkBlue;
        opacity: 0.8;
      }
    }

    ._separator {
      margin: 0 4px;
    }
  }
}
