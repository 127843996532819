.page.-order-tracking-page {
  background-color: $grey1;

  > ._page-content-wrapper {
    padding-bottom: 2rem;

    .max-width-wrapper {
      .box-tracking.order {
        display: flex;
        padding-top: 34px;
        padding-bottom: 54px;

        @include bp(1000px) {
          flex-direction: column;
        }

        @include bp($mobileBreakpoint) {
          margin-top: 20px;
        }

        .box-tracking__main {
          width: 100%;
          margin-right: 48px;

          @include bp(1200px) {
            margin-right: 36px;
          }

          @include bp($mobileBreakpoint) {
            margin: 0 auto;
          }
        }

        .box-tracking__side {
          display: flex;
          flex-direction: column;
          max-width: 325px;
          min-width: 300px;

          @include bp(1000px) {
            margin-top: 40px;
          }

          @include bp($mobileBreakpoint) {
            margin-top: 24px;
          }
        }
      }
    }
  }
}
