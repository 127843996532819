.page.-checkout-page {
  > .max-width-wrapper > ._loader-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  > .max-width-wrapper > ._empty-wrapper {
    padding: 24px 0;

    > ._text {
      @include typography-desktop-text-x-small($grey3);
      margin-bottom: 14px;
    }
  }

  > .max-width-wrapper {
    ._left-content {
      width: 100%;
      margin-right: 48px;
      display: flex;
      flex-direction: column;

      @include bp(1200px) {
        margin-right: 36px;
      }

      @include bp($mobileBreakpoint) {
        margin: 0 auto;
      }
    }

    ._right-content {
      max-width: 350px;
      min-width: 350px;
      width: 100%;

      @include bp(1024px) {
        margin-top: 40px;
        max-width: 100%;
        min-width: 0;
      }

      > ._side-links-section {
        background: $grey1;
        border-radius: 8px;
        padding: 24px;
        margin-top: 32px;
        z-index: 2;

        > ._subtitle {
          @include typography-desktop-text-large;
          margin-bottom: 8px;
        }

        > ._links {
          padding: 0;
          margin: 0;
          list-style: none;

          > li {
            &:not(:last-child) {
              margin-bottom: 8px;
            }

            > a {
              @include typography-desktop-text-x-small($grey5);

              &:hover {
                color: $primaryPink;
              }
            }
          }
        }
      }
    }
  }
}
