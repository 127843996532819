// ------------------------------
// Colors
// ------------------------------
$primaryBlack: #08051b;
$primaryPink: #e3068c;
$primaryPinkHover: #e940a1;
$primaryDarkBlue: #14142b;

$lightPink: #fff2f7;

$grey1: #f7f7fc;
$grey2: #d6d8e7;
$grey3: #a0a3bd;
$grey4: #6e7191;
$grey5: #4e4b66;

$darkRed: #ff2727;

// ------------------------------
// Breakpoints
// ------------------------------
$mobileLBreakpoint: 425px;
$mobileBreakpoint: 768px;
$mobileBreakpointCatalogue: 900px;
$tabletBreakpoint: 1024px;

// ------------------------------
// Animations
// ------------------------------
$transition: cubic-bezier(0.2, 0.8, 0, 1);

$mainMenuTransition: ease-in-out;
$mainMenuSlideDuration: 300ms;
$mainMenuSlideSkew: 0;

// ------------------------------
// Box-shadows
// ------------------------------
$box-shadow-full:
  0px 16px 24px rgba(0, 0, 0, 0.06),
  0px 2px 6px rgba(0, 0, 0, 0.04),
  0px 0px 1px rgba(0, 0, 0, 0.04);
$soft-drop-shadow: 0px 8px 16px rgba(17, 17, 17, 0.04);

// ------------------------------
// Filters
// ------------------------------
$primaryPinkFilter: invert(32%) sepia(88%) saturate(7145%) hue-rotate(311deg) brightness(87%) contrast(109%);
$invertFilter: brightness(0) grayscale(1) invert(100%);
$primaryBlackFilter: brightness(10%);
$darkFilter: brightness(50%);

@mixin box-shadow-full {
  box-shadow: $box-shadow-full;
}

@mixin box-shadow-md {
  box-shadow:
    0 4px 6px -1px rgb(0 0 0 / 0.1),
    0 2px 4px -2px rgb(0 0 0 / 0.1);
}

// ------------------------------
// Utils
// ------------------------------
@import './util/bp-mixins';
@import './util/animations';
@import './util/fonts';
