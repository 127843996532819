.page.-reset-password-form-page {
  .reset-password-form-wrapper {
    margin: 48px auto;
    max-width: 600px;

    form > * {
      margin-top: 8px;
    }
  }
}
