div.box-tracking-entry {
  background: #fff;
  padding: 125px 0;

  border-radius: 4px;
  border: 1px solid $grey2;

  display: flex;
  justify-content: center;
  align-items: center;

  @include bp($mobileBreakpoint) {
    border: 0 solid;
    padding: 24px 14px;

    @include box-shadow-full;
  }

  .box-tracking-entry__content {
    width: 100%;
    max-width: 325px;
  }
}
