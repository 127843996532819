.page.-customer-data-page,
.page.-customer-company-details-page,
.modal > .customer-data-modal {
  .panel-item {
    margin: 0 8px;
    flex: 1;
    padding: 32px 42px;
    border-radius: 8px;
    background-color: #fff;
    position: relative;

    // Substract side margin
    min-width: max(300px, calc(50% - 32px));

    &.-full-width {
      min-width: calc(100% - 32px);
    }
  }

  .panel-title {
    font-size: 24px;
    line-height: 32px;
    color: $primaryDarkBlue;
    font-weight: 600;
    margin-right: 12px;
  }

  .panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  .panel-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#fff, 0.8);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include bp($mobileBreakpoint) {
    .panel-item {
      padding: 32px 16px;
      margin: 8px 0;
    }
  }

  form {
    .text-input,
    .searchable-select-wrapper {
      margin-bottom: 8px;
    }

    button.submit-btn {
      margin-top: 16px;
      width: 100%;
    }

    .row {
      display: flex;
      margin-bottom: 8px;

      @include bp($mobileBreakpoint) {
        flex-direction: column;
      }

      .text-input {
        width: 100%;

        &:nth-child(even) {
          margin-left: 8px;

          &:not(:last-child) {
            margin-right: 8px;
          }

          @include bp($mobileBreakpoint) {
            margin-right: 0;
            margin-left: 0;
            margin-top: 8px;
          }
        }
      }
    }

    .form-footer {
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;

      .button:nth-child(even) {
        margin-left: 8px;
      }
    }
  }

  .customer-address-list {
    .customer-address {
      border: 1px solid $grey2;
      border-radius: 8px;
      padding: 24px 16px 14px 24px;

      display: flex;
      justify-content: space-between;

      &:not(:last-child) {
        margin-bottom: 24px;
      }

      @include bp($mobileBreakpoint) {
        display: block;
        padding: 8px 8px 6px 12px;

        &:not(:last-child) {
          margin-bottom: 12px;
        }
      }
    }

    .details {
      .address {
        @include typography-desktop-text-medium($primaryBlack);
        @include minbp($mobileBreakpoint) {
          margin-bottom: 8px;
        }
      }

      ul {
        @include typography-desktop-text-x-small($grey5);
        list-style-type: none;
        padding: 0;
        margin: 0;

        @include bp($mobileBreakpoint) {
          display: inline-flex;
          flex-wrap: wrap;

          li:not(:empty) {
            margin-right: 4px;
            flex-shrink: 0;

            &:not(:last-child):after {
              content: ',';
            }
          }
        }
      }
    }

    .actions {
      display: flex;

      @include minbp($mobileBreakpoint) {
        flex-direction: column;
        align-items: flex-end;
      }

      @include bp($mobileBreakpoint) {
        margin-top: 8px;

        flex-wrap: wrap-reverse;
        justify-content: space-between;
        align-items: center;
      }

      .buttons {
        display: flex;

        @include bp($mobileBreakpoint) {
          margin-right: 12px;
        }

        button {
          &:not(:last-child) {
            margin-right: 8px;
          }

          @include bp($mobileBreakpoint) {
            &:not(:last-child) {
              margin-right: 4px;
            }
          }
        }
      }

      .radio-input {
        margin-top: auto;

        @include bp($mobileBreakpoint) {
          margin-top: 0;
          margin-bottom: 8px;
        }
      }
    }
  }

  .customer-address-form {
    visibility: hidden;
    opacity: 0;
    max-height: 0;

    &.-show {
      max-height: inherit;
      opacity: 1;
      visibility: visible;

      margin-bottom: 20px;
    }

    ._title {
      @include typography-desktop-text-medium;
      margin-bottom: 17px;
    }

    ._company-col-wrapper {
      display: flex;

      > * {
        flex: 1;

        &:first-child:not(:only-child) {
          margin-right: 8px;
        }
      }

      @include bp($mobileBreakpoint) {
        flex-direction: column;

        > * {
          &:first-child:not(:only-child) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
