.checkout-breadcrumbs {
  display: flex;

  > ._step,
  > ._sep {
    @include typography-label-caption($grey3);
    text-transform: uppercase;
    white-space: nowrap;
    cursor: default;

    &.-completed {
      color: $grey3;

      &:hover {
        cursor: pointer;
        color: $primaryDarkBlue;
        opacity: 0.8;
      }
    }

    &.-active {
      color: $primaryDarkBlue;
      font-weight: 500;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }

    @include bp($mobileBreakpoint) {
      &.-collapse-on-mobile {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 28px;
      }
    }
  }

  > a._step:hover {
    opacity: 1;
  }
}
