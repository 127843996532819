.campaign-banner {
  width: 100%;
  display: block;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 8px;
  padding-bottom: 51.1%;

  @include bp($mobileBreakpoint) {
    padding-bottom: 104.27%;
  }

  // ----------
  // Text and image
  // ----------
  &.-text-and-image {
    background: $primaryDarkBlue;

    &.-mobile {
      @include minbp($mobileBreakpoint) {
        display: none;
      }
    }

    &.-desktop {
      @include bp($mobileBreakpoint) {
        display: none;
      }
    }

    > ._content {
      position: absolute;
      display: flex;
      height: 100%;
      width: 100%;

      > ._left {
        flex: 1;
        word-break: break-word;
        position: relative;

        > img {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          transition: all 0.8s $transition;

          @include minbp($mobileBreakpoint) {
            display: none;
          }
        }

        > ._item-wrapper {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: column;

          > ._label-wrapper {
            > ._label {
              padding: 6px;
              text-transform: uppercase;
              border-radius: 100px;
              background-color: $primaryPink;
              @include typography-label-caption(#fff);

              &:hover {
                background-color: $primaryPinkHover !important;
              }
            }
          }
        }
      }

      > ._right {
        position: relative;
        height: 100%;
        flex: 1;

        @include bp($mobileBreakpoint) {
          display: none;
        }

        > img {
          position: absolute;
          object-fit: cover;
          height: 100%;
          width: 100%;
          transition: all 0.8s $transition;
        }
      }

      &:hover {
        > ._right > img {
          transform: scale(1.015) translateZ(0);
        }

        > ._left > ._item-wrapper > ._round-btn {
          background: #fff;
          transform: scale(1.015) translateZ(0);

          > svg > path {
            stroke: $primaryPink;
          }
        }
      }
    }
  }

  // ----------
  // Single image
  // ----------
  &.-single-image {
    position: relative;
    display: flex;

    &.-mobile {
      @include minbp($mobileBreakpoint) {
        display: none;
      }
    }

    &.-desktop {
      @include bp($mobileBreakpoint) {
        display: none;
      }
    }

    &.-full-width {
      max-height: 686.5px;
    }

    &.-products-image {
      margin-bottom: 15px;
    }

    > img {
      position: absolute;
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: all 0.8s $transition;
    }

    &:hover > img {
      transform: scale(1.015) translateZ(0);
    }
  }
}
