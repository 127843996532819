.catalogue-product-wrapper {
  position: relative;
  display: flex;

  .catalogue-product-link {
    flex-grow: 1;

    &:visited {
      color: $grey5;

      &:hover {
        color: $primaryPink;
      }
    }
  }

  .catalogue-product {
    overflow: hidden;
    position: relative;
  }

  ._name-wrapper {
    ._name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      @include inter(700);

      word-wrap: break-word;
      word-break: break-word;
      hyphens: auto;

      @include bp($mobileBreakpoint) {
        font-size: 16px;
        line-height: 18px;
      }

      > .product-labels {
        display: inline-block;
        vertical-align: bottom;
        @include bp($mobileBreakpoint) {
          display: none;
        }
      }
    }
  }
}
