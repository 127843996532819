.product-page-price {
  > .-main-price,
  > ._old-price {
    white-space: nowrap;
  }

  > ._main-price {
    margin-right: 8px;
    @include typography-desktop-text-large;

    &.-sale {
      color: $primaryPink;
    }
  }

  > ._old-price {
    color: $grey4;
    font-size: 14px;
    line-height: 24px;
    text-decoration: line-through;
  }

  &.-mini {
    flex-direction: column;
    text-align: right;

    > ._main-price {
      font-size: 18px;
      line-height: 20px;

      @include bp(768px) {
        font-size: 16px;
        line-height: 18px;
      }

      color: $primaryBlack;
    }
  }
}
