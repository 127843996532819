.account-layout {
  margin-bottom: 64px;
  display: flex;

  @include bp(1200px) {
    display: block;

    .account-sidebar {
      display: none;
    }
  }

  .account-content {
    flex: 1;
  }

  .account-sidebar {
    margin-right: 64px;
  }
}
