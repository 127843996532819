.footer-menu {
  > div > .menu-item,
  > .menu-item {
    > .menu-item__text {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 32px;
      @include inter(600);
      color: #fff;

      @include bp(1024px) {
        margin-bottom: 24px;
      }

      @include bp(768px) {
        margin-bottom: 16px;
      }
    }
  }

  .menu-item__children {
    list-style: none;
    padding: 0;

    > .menu-item {
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
