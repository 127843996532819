.menu-item-hover-menu {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  cursor: auto;

  &::after {
    content: ' ';
    height: 64px;
    bottom: 0;
    right: 0;
    left: 0;
    top: calc(100% - 64px);
    position: absolute;

    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $grey1 100%);
    border-radius: 0 0 4px 4px;
  }

  .hover-menu__content {
    padding: 24px 32px;
    width: 100%;
    max-height: 100%;
    position: relative;
    overflow: auto;
  }

  .menu-hover__items {
    columns: 3;
    transition: opacity 300ms $transition;
    list-style: none;

    // Animation
    &.enter {
      opacity: 0;
    }

    &.enter-done {
      opacity: 1;
    }

    &.exit {
      opacity: 1;
    }

    &.exit-done {
      opacity: 0;
    }

    > .menu-item {
      break-inside: avoid;
      @include typography-desktop-text-small($primaryBlack);
      padding: 20px;
      background: $grey1;
      border-radius: 8px;
      margin-bottom: 12px;

      .menu-item__text {
        display: flex;
        flex-direction: row;
        align-items: center;

        ._chevron {
          margin-left: 8px;

          height: 16px;
          width: 16px;
          min-width: 16px;

          > path {
            stroke: $grey5;
          }
        }

        &:hover {
          color: $primaryPink;

          ._chevron > path {
            stroke: $primaryPink;
          }
        }
      }

      .menu-item__children {
        display: flex;
        flex-direction: column;

        margin-top: 8px;
        padding: 0;

        .menu-item {
          @include typography-desktop-text-x-small($grey5);
          margin-bottom: 8px;
          display: inline-flex;
        }
      }
    }
  }

  // Animations
  transition: all 0.4s $transition;

  &.enter {
    opacity: 0;
  }

  &.enter-active {
    opacity: 1;
  }

  &.exit {
    opacity: 1;
  }

  &.exit-active {
    opacity: 0;
  }
}
