div.paginator {
  display: flex;
  align-items: center;
  margin: 6px 0;
  flex: 1;

  @include bp($tabletBreakpoint) {
    justify-content: center;
    flex: 0;
    width: 100%;
    background: $grey1;
    border-radius: 32px;
    flex: 1;
    margin: 0;
  }
}

._page-btns {
  display: flex;
  align-items: center;
  height: 40px;

  @include minbp($mobileBreakpointCatalogue) {
    background: $grey1;
    border-radius: 32px;
    border: 1px solid rgba($primaryDarkBlue, 0.1);
  }
}

._pagination-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @include typography-desktop-link-x-small($primaryDarkBlue);

  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 50%;
  padding: 0;
  border-color: transparent;
  background-color: transparent;

  transition: all 0.3s $transition;

  &.-small {
    margin: 1px;

    &.-ellipsis,
    &.-arrow {
      width: 16px;
      &.-left {
        margin-left: 0;
      }
      &.-right {
        margin-right: 0;
      }
    }
  }

  &.-active {
    cursor: default;
    background-color: $primaryDarkBlue;
    color: $grey2;
  }

  &:not(.-arrow, .-active, .-loading, .-disabled, .-ellipsis):hover {
    background-color: rgba($primaryDarkBlue, 0.1);

    a {
      color: black;
    }
  }

  &.-loading:not(.-active) {
    cursor: default;
    border-color: $primaryPink;
    color: $primaryPink;
    opacity: 0.5;
  }

  &.-arrow,
  &.-ellipsis {
    margin: 0;
    color: $primaryDarkBlue;
    background-color: transparent;
  }

  &.-ellipsis {
    cursor: initial;
  }

  &.-arrow {
    margin: 0 4px;

    a,
    svg {
      height: 16px;
      width: 16px;
      min-width: 16px;
      display: flex;
    }

    &.-right svg {
      margin-right: -2px;
    }

    &.-left svg {
      margin-left: -2px;
    }

    &.-disabled {
      &,
      a {
        cursor: default;
        opacity: 0.7;
      }
    }

    &:not(.-disabled):hover {
      svg {
        path {
          stroke: $primaryPink;
          fill: $primaryPink;
        }
      }
    }
  }
}

div.paginator-wrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-self: center;
  // align-items: flex-end;

  @include bp($tabletBreakpoint) {
    gap: 8px;
  }
}
